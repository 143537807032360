import { LoadingContext, LoadingContextType } from 'modules/loading';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FileInfoDto, PersonDto, PersonRegisterDto, RelationDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import { ClientDto, FileDto, FileSearchDto, useManagementApi } from 'modules/managementApi';
import 'scss/application.scss';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import useForm, { FormItemGroupType, FormItemType, FormItems, FormValuesType } from 'modules/form';
import validators from 'modules/validators';
import { FileRegister } from './FileRegister';
import { toDateString } from 'modules/utils';
import { FileDelete } from './FileDelete';

export const FileSearch = () => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);
    const navigate = useNavigate();
    const managementApi = useManagementApi(context);

    const [forRegister, setForRegister] = useState<boolean>(false);
    const [forDelete, setForDelete] = useState<FileDto | undefined>(undefined);
    const [files, setFiles] = useState<Array<FileDto> | undefined>(undefined);

    const form = useForm([
        new FormItemGroupType([
            new FormItemType({
                name: "relationName",
                label: "関係の名称",
                type: "text",
                required: false,
                validators: validators.name,
                initialValue: "",
            }),
            new FormItemType({
                name: "fileName",
                label: "ファイル名",
                type: "text",
                required: false,
                validators: validators.name,
                initialValue: "",
            }),
        ], "name", "名称"),
        new FormItemGroupType([
            new FormItemType({
                name: "fileSizeFrom",
                label: "最小",
                type: "text",
                required: false,
                validators: validators.number,
                initialValue: "",
            }),
            new FormItemType({
                name: "fileSizeTo",
                label: "最大",
                type: "text",
                required: false,
                validators: validators.number,
                initialValue: "",
            }),
        ], "fileSize", "ファイルサイズ"),
        new FormItemGroupType([
            new FormItemType({
                name: "registeredFrom",
                label: "自",
                type: "date",
                required: false,
                initialValue: "",
            }),
            new FormItemType({
                name: "registeredTo",
                label: "至",
                type: "date",
                required: false,
                initialValue: "",
            }),
        ], "registerDate", "登録日"),
        new FormItemGroupType([
            new FormItemType({
                name: "publish",
                label: "公開",
                type: "lever",
                required: false,
                initialValue: null,
            }),
            new FormItemType({
                name: "video",
                label: "動画",
                type: "lever",
                required: false,
                initialValue: null,
            }),
        ], "registerDate", "その他"),

    ], (values: FormValuesType) => {
        search(values);
    });

    const search = (values: FormValuesType) => {
        const fileSearchDto: FileSearchDto = {
            relationName: values["relationName"] as string,
            fileName: values["fileName"] as string,
            fileSizeFrom: null,
            fileSizeTo: null,
            createDateFrom: null,
            createDateTo: null,
            publish: values["publish"] as boolean,
            video: values["video"] as boolean,
        };
        managementApi.searchFile(fileSearchDto)
        .then(fileListDto => {
            setFiles(fileListDto.files);
        })
        .catch(error => {
            toast.error("ファイルの検索に失敗しました。");
        });
    };

    const download = (file: FileDto) => {
        context.api.getFileAccess(file.fileId, 2)
        .then(fileAccessDto => {
            context.api.getFile(fileAccessDto.passCode, null)
            .then(data => {
                const blob = new Blob([data], {
                    type: data.type
                });
                var FileSaver = require('file-saver');
                FileSaver.saveAs(blob, fileAccessDto.fileName);
            }).catch(error => {
                toast.error("ファイルのダウンロードに失敗しました。");
            })
        }).catch(error => {
            toast.error("ファイルのダウンロードに失敗しました。");
        })
    }

    const onClose = (done: boolean) => {
        setForRegister(false);
        setForDelete(undefined);
        if(done) {
            search(form.values);
        }
    }

    useEffect(() => {
        search(form.values);
    }, []);

    return (
        <div>
            <h3 className="py-4">ファイル検索</h3>
            <FormItems { ...form } />
            <div className="d-flex justify-content-center py-2">
                <button className="btn btn-primary" onClick={form.onSubmit}>検索</button>
            </div>
            <div className="d-flex justify-content-center py-2">
                <Link to={"/"}>
                    <span>戻る</span>
                </Link>
            </div>

            <h3 className="py-4">ファイルリスト</h3>
            {(files !== undefined && files.length === 0) && (
                <div className="col col-12 d-flex justify-content-center py-5">
                    <span>何もありません。</span>
                </div>
            )}
            <div className="d-flex flex-row-reverse">
                <Button variant="outline-secondary" onClick={() => setForRegister(true)}>
                    <i className="bi bi-plus"></i>
                </Button>
            </div>
           {(files !== undefined && 0 < files.length) &&
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Size</th>
                            <th scope="col">Relation</th>
                            <th scope="col">Created</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {files.map((file, index) =>
                            <tr key={file.fileId}>
                                <td scope="col" className="text-truncate">{file.fileId}</td>
                                <td scope="col" className="text-truncate">{file.fileName}</td>
                                <td scope="col" className="text-truncate ">{file.fileSize}</td>
                                <td scope="col" className="text-truncate">{file.relationName}</td>
                                <td scope="col" className="text-truncate">{toDateString(file.createTime)}</td>
                                <td scope="col" className="text-truncate">
                                    <Button variant="outline-secondary" className="mx-2" onClick={() => download(file)}>
                                        <i className="bi bi-download"></i>
                                    </Button>
                                    <Button variant="outline-secondary" className="mx-2" onClick={() => setForDelete(file)}>
                                        <i className="bi bi-trash"></i>
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
            <div className={"modal show"} style={{ display: 'block', position: 'initial' }}>
                {forRegister && <FileRegister onClose={onClose} />}
                {forDelete !== undefined && <FileDelete file={forDelete} onClose={onClose} />}
            </div>
        </div>
    );
}
