import React, { createContext, useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from "styled-components";

interface LoadingPropsType {
    size?: number;
    interval?: number;
    children: React.ReactNode | Array<React.ReactNode>;
}

export type LoadingContextType = {
    invoke: () => void;
    revoke: () => void;
    state: number;
}

export const LoadingContext = createContext({} as LoadingContextType);

const baseSize = 2.5;
const sizes = [
    0,
    (baseSize * 0.7),
    baseSize,
    (baseSize * 0.7),
    0,
    (baseSize * -0.7),
    (baseSize * -1),
    (baseSize * -0.7)
];
const opacities = [1.0, 0.2, 0.2, 0.2, 0.2, 0.3, 0.5, 0.7];

const loadingKeyframes = keyframes
    `${sizes.map((sizeI, indexI) => (
        `${indexI === 0 ? "100%, " : ""} ${indexI / sizes.length * 100}% {box-shadow: ${
            sizes.map((sizeJ, indexJ) => {
                let p = indexJ;
                let q = (indexJ + (sizes.length * 3 / 4)) % sizes.length;
                let r = (sizes.length + indexJ - indexI) % sizes.length;
                // 255, 161, 10
                return ` ${sizes[p]}em ${sizes[q]}em 0em 0em rgba(255, 255, 255, ${opacities[r]})`;
            })
        }}`
    ))}`
;

const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2147483647;
`;

const LoadingOverlay = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000;
    opacity: 0.5;
    top: 0;
    left: 0;
`;

const Loader = styled.div`
    font-size: 8px;
    top: 50%;
    left: 50%;
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    position: relative;
    -webkit-animation: ${loadingKeyframes} 1.1s infinite ease;
    animation: ${loadingKeyframes} 800ms infinite ease;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
`;

export const LoadingContainer: React.FC<LoadingPropsType> = (props) => {
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(0);

    const onInvoke = useCallback(() => {
        setState(n => n + 1);
    }, []);

    const onRevoke = useCallback(() => {
        setState(n => n - 1);
    }, []);


    useEffect(() => {
        if(state === 0) {
            setLoading(false);
        } else if(!loading) {
            setLoading(true);
        }
    }, [state, loading, setLoading])

    const container: LoadingContextType = {
        invoke: onInvoke,
        revoke: onRevoke,
        state: state,
    };

    return (
        <LoadingContext.Provider value={container}>
            {loading &&
                <LoadingWrapper>
                    <LoadingOverlay />
                    <Loader />
                </LoadingWrapper>
            }
            {props.children}
        </LoadingContext.Provider>
    );
}
