import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MailTemplateDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import { CategoryListDto, useManagementApi } from 'modules/managementApi';
import 'scss/application.scss';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { MailTemplateRegister } from './MailTemplateRegister';
import { MailTemplateDelete } from './MailTemplateDelete';

type MailTemplateForRegister = {
    category: string,
    mailTemplate?: MailTemplateDto,
};

export const MailTemplateList = () => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);
    const params = useParams();
    const navigate = useNavigate();
    const managementApi = useManagementApi(context);

    const [category, setCategory] = useState<string | undefined>(undefined);
    const [categories, setCategories] = useState<Array<string> | undefined>(undefined);
    const [mailTemplates, setMailTemplates] = useState<Array<MailTemplateDto> | undefined>(undefined);
    const [forSend, setForSend] = useState<MailTemplateDto | undefined>(undefined);
    const [forSent, setForSent] = useState<MailTemplateDto | undefined>(undefined);
    const [forRegister, setForRegister] = useState<MailTemplateForRegister>(undefined);
    const [forDelete, setForDelete] = useState<MailTemplateDto | undefined>(undefined);

    const categoryRef = useRef(null);

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if(categories) {
            if(category) {
                let exists = false;
                for (let i = 0; i < categories.length; i++) {
                    if(categories[i] === category) {
                        exists = true;
                        break;
                    }
                }
                if(!exists) {
                    setCategory(categories[0]);
                }
            } else {
                setCategory(categories[0]);
            }
        } else {
            setCategory(undefined);
        }
    }, [categories]);

    useEffect(() => {
        if(category) {
            context.api.listMailTemplate(null, categories[categoryRef.current.selectedIndex], null)
            .then(mailTemplateListDto => {
                if(0 < mailTemplateListDto.mailTemplates.length) {
                    setMailTemplates(mailTemplateListDto.mailTemplates);
                } else if(!mailTemplates) {
                    setMailTemplates(undefined);
                }
            })
            .catch(error => {
                toast.error("メールテンプレートリストの取得に失敗しました。");
                setMailTemplates(undefined);
            });
        } else if(mailTemplates) {
            setMailTemplates(undefined);
        }
    }, [category]);

    const load = () => {
        managementApi.listMailCategory()
        .then(categoryListDto => {
            if(categoryListDto.categories.length == 0) {
                setCategories(undefined);
            } else {
                setCategories(categoryListDto.categories);
            }
        })
        .catch(error => {
            toast.error("カテゴリーリストの取得に失敗しました。");
            setCategories(undefined);
        })
    };

    const onClose = (done: boolean) => {
        if(forRegister) {
            setForRegister(undefined);
        }
        if(forDelete) {
            setForDelete(undefined);
        }
        if(done) {
            load();
        }
    };

    return (
        <div>
            <h3 className="py-4">{`${context.user.relation_name} メールテンプレートリスト`}</h3>
            {categories && <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">カテゴリー</InputGroup.Text>
                <Form.Select id="category" name="category" ref={categoryRef} onChange={() => setCategory(categories[categoryRef.current.selectedIndex])}>
                    {categories.map((c, index) => 
                        <option key={`category-${index}`} value={index} selected={c === category}>{c}</option>
                    )}
                </Form.Select>
            </InputGroup>}
            <div className="d-flex flex-row-reverse">
                <Button variant="outline-secondary" onClick={() => setForRegister({category: category ? category : ""})}>
                    <i className="bi bi-plus"></i>
                </Button>
            </div>
            {(categories && !mailTemplates) && (
                <div className="col col-12 d-flex justify-content-center py-5">
                    <span>登録されていません。</span>
                </div>
            )}
            {(categories && mailTemplates) &&
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">名称</th>
                            <th scope="col" className="text-center">検証</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {mailTemplates.map((mailTemplate, index) =>
                            <tr key={mailTemplate.mailTemplateId}>
                                <td scope="col" className="text-truncate">{mailTemplate.mailTemplateId}</td>
                                <td scope="col" className="text-truncate">{mailTemplate.mailTemplateName}</td>
                                <td scope="col" className="text-center text-truncate ">{mailTemplate.verification ? "◯" : "×"}</td>
                                <td scope="col" className="text-truncate">
                                    <Button variant="outline-secondary" className="mx-2" onClick={() => navigate(`/mail/${category}/${mailTemplate.mailTemplateId}`)}>
                                        <i className="bi bi-send"></i>
                                    </Button>
                                    <Button variant="outline-secondary" className="mx-2" onClick={() => setForRegister({
                                        category: categories[categoryRef.current.selectedIndex],
                                        mailTemplate: mailTemplate
                                    })}>
                                        <i className="bi bi-pencil"></i>
                                    </Button>
                                    <Button variant="outline-secondary" className="mx-2" onClick={() => setForDelete(mailTemplate)}>
                                        <i className="bi bi-trash"></i>
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
            <div className="d-flex justify-content-center py-2">
                <Link to={`/`}>
                    <span>戻る</span>
                </Link>
            </div>
            <div className={"modal show"} style={{ display: 'block', position: 'initial' }}>
                {forRegister && <MailTemplateRegister { ...forRegister } onClose={onClose} />}
                {forDelete && <MailTemplateDelete category={categories[categoryRef.current.selectedIndex]} mailTemplate={forDelete} onClose={onClose} />}
            </div>
        </div>
    );
}
