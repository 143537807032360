import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import { ClientDto, useManagementApi } from 'modules/managementApi';
import 'scss/application.scss';
import { Button } from 'react-bootstrap';

export const ClientList = () => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);
    const navigate = useNavigate();
    const managementApi = useManagementApi(context);

    const [clients, setClients] = useState<Array<ClientDto> | null>(null);

    useEffect(() => {
        managementApi.listClient()
        .then(clientListDto => {
            setClients(clientListDto.clients);
        })
        .catch(error => {
            toast.error("クライアントリストの取得に失敗しました。");
        })
    }, []);

    const onClient = (clientId: string) => {
        navigate("/client/" + clientId);
    };

    return (
        <div>
            <h3 className="py-4">{context.user.relation_name} クライアントリスト</h3>
            {(clients !== null && clients.length === 0) && (
                <div className="col col-12 d-flex justify-content-center py-5">
                    <span>何もありません。</span>
                </div>
            )}
            <div className="d-flex flex-row-reverse">
                <Button variant="outline-secondary" onClick={() => navigate("/client-register")}>
                    <i className="bi bi-plus"></i>
                </Button>
            </div>
            {(clients !== null && 0 < clients.length) &&
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Client ID</th>
                            <th scope="col">Client Name</th>
                            <th scope="col" className="text-center">Management</th>
                            <th scope="col">Origin</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {clients.map((client, index) =>
                            <tr key={client.clientId}>
                                <td scope="col" className="text-truncate">{client.clientId}</td>
                                <td scope="col" className="text-truncate">{client.clientName}</td>
                                <td scope="col" className="text-center text-truncate ">{client.management ? "◯" : "×"}</td>
                                <td scope="col" className="text-truncate">{client.origin}</td>
                                <td scope="col" className="text-truncate">
                                    <Button variant="outline-secondary" className="mx-2" onClick={() => navigate(`/authorization/${client.clientId}`)}>
                                        <i className="bi bi-people"></i>
                                    </Button>
                                    <Button variant="outline-secondary" className="mx-2" onClick={() => navigate(`/client-edit/${client.clientId}`)}>
                                        <i className="bi bi-pencil"></i>
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
            <div className="d-flex justify-content-center py-2">
                <Link to={"/"}>
                    <span>戻る</span>
                </Link>
            </div>
        </div>
    );
}
