import React, { useContext, useEffect, useState } from 'react'
import { SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import 'scss/application.scss';
import { Button, Modal } from 'react-bootstrap';
import useForm, { FormItemGroupType, FormItemType, FormItems, FormValuesType } from 'modules/form';
import validators from 'modules/validators';

type PersonRegisterProps = {
    personId?: number,
    name: string,
    email: string,
    password?: string,
    onClose: (done: boolean) => void,
};

export const PersonRegister = (props: PersonRegisterProps) => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);

    const items: Array<FormItemType | FormItemGroupType> = [
        new FormItemType({
            name: "personId",
            label: "ID",
            type: "text",
            required: true,
            validators: validators.id,
            initialValue: props.personId ? String(props.personId) : "",
            readOnly: props.personId !== undefined
        }),
        new FormItemType({
            name: "name",
            label: "名前",
            type: "text",
            required: true,
            validators: validators.name,
            initialValue: props.name,
        }),
        new FormItemType({
            name: "email",
            label: "メールアドレス",
            type: "text",
            required: true,
            validators: validators.email,
            initialValue: props.email,
        }),
    ];
    if(!props.personId) {
        items.push(
            new FormItemType({
                name: "password",
                label: "パスワード",
                type: "text",
                required: true,
                validators: validators.password,
                initialValue: props.password,
            })
        );
    }

    const actionName = props.personId ? "更新" : "登録";

    const form = useForm(items, (values: FormValuesType) => {
        const exec: Promise<void> = props.personId ?
            context.api.updatePerson(props.personId, {
                name: values["name"] as string,
                email: values["email"] as string
            }):
            context.api.registerPerson(parseInt(values["personId"] as string), {
                name: values["name"] as string,
                email: values["email"] as string,
                password: values["password"] as string,
            });
        exec.then(() => {
            toast.info(`個人を${actionName}しました。`);
            props.onClose(true);
        }).catch(error => {
            toast.error(`個人の${actionName}に失敗しました。`);
            props.onClose(false);
        });
    });

    return (
        <div className={"modal show"} style={{ display: 'block', position: 'initial' }}>
            <Modal show={true} size="lg" onHide={() => props.onClose(false)} scrollable={false}>
                <Modal.Header closeButton>
                    個人登録
                </Modal.Header>
                <Modal.Body className="m-4">
                    <FormItems { ...form } />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.onClose(false)}>キャンセル</Button>
                    <Button variant="primary" onClick={form.onSubmit}>{actionName}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
