import { ApiProperties, SoContextType } from 'react-sonare';
import axios, { AxiosRequestConfig } from 'axios';

export interface RelationListDto {
    limit: number,
    offset: number,
    total: number,
    relations: Array<RelationDto>,
}

export interface RelationDto {
    relation: number,
    relationName: string,
}

export interface PersonListDto {
    limit: number,
    offset: number,
    total: number,
    persons: Array<PersonDto>,
}

export interface PersonDto {
    personId: number,
    email: string,
    name: string,
    password: string,
    valid: boolean,
}

export interface ApplicationDto {
    applicationId: number,
    contractId: number,
    applicationKey: string,
    applicationName: string,
    publicKey: string,
    privateKey: string,
    builtinSecret: string,
    available: boolean,
    valid: boolean,
}

export interface ClientDto {
    clientId: string,
    clientName: string,
    clientSecret: string,
    accessSeconds: number,
    refreshSeconds: number,
    management: boolean,
    fileId: number,
    color: number,
    origin: string,
    loginUrl: string,
    callbackUrl: string,
    registerTime: Date,
}

export interface ClientListDto {
    limit: number,
    offset: number,
    total: number,
    clients: Array<ClientDto>,
}

export interface ApplicationExistsDto {
    exists: boolean,
}

export interface FileDto {
    relation: number,
    relationName: string,
    directoryId: number,
    fileId: number,
    fileSize: number,
    fileName: string,
    createTime: Date,
    publishTime: Date,
    video: boolean,
    fragments: number,
}

export interface UserApplyDto {
    email: string,
    name: string,
}

export interface ClientUserRegisterDto {
    email: string,
    name: string,
    password: string,
}

export interface ClientUserListDto {
    limit: number,
    offset: number,
    total: number,
    clients: Array<ClientDto>,
}

export interface ApplicationListDto {
    limit: number,
    offset: number,
    total: number,
    applications: Array<ApplicationSummaryDto>,
}

export interface FileSearchDto {
    relationName: string | null,
    fileSizeFrom: number | null,
    fileSizeTo: number | null,
    fileName: string | null,
    createDateFrom: Date | null,
    createDateTo: Date | null,
    publish: boolean | null,
    video: boolean | null,
}

export interface MailTemplateForSendDto {
    variables: Array<string>,
    relation: number,
    mailTemplateId: string,
    mailTemplateName: string,
    verification: boolean,
    fromEmail: string,
    fromName: string,
    toEmail: string,
    toName: string,
    subject: string,
    content: string,
}

export interface UserRegisterDto {
    email: string,
    name: string,
    password: string,
}

export interface ContractDto {
    contractId: number,
    validFrom: Date,
    validTo: Date,
    contractType: string,
    personId: number,
    komojuCustomerId: string,
    registerTime: Date,
    at: Date,
    personNumber: string,
    corporateNumber: string,
    birthDate: Date,
    sexCd: string,
    lastName: string,
    lastNameKana: string,
    firstName: string,
    firstNameKana: string,
    zipcode: string,
    prefectureCd: number,
    localGovernmentCd: number,
    address0: string,
    address1: string,
    address2: string,
    tel: string,
}

export interface ApplicationManagerListDto {
    applicationManagers: Array<ApplicationManagerDto>,
}

export interface PasswordRegisterDto {
    password: string,
}

export interface ApplicationSummaryDto {
    contractId: number,
    applicationId: number,
    applicationKey: string,
    applicationName: string,
}

export interface ClientUserDto {
    personId: number,
    email: string,
    name: string,
}

export interface FileListDto {
    limit: number,
    offset: number,
    total: number,
    files: Array<FileDto>,
}

export interface ApplicationApplyDto {
    applicationKey: string,
    applicationName: string,
}

export interface ApplicationManagerRegisterDto {
    email: string,
}

export interface ClientRegisterResultDto {
    clientSecret: string,
}

export interface PasswordApplyDto {
    email: string,
}

export interface ApplicationManagerDto {
    personId: number,
    email: string,
    name: string,
}

export interface UserDto {
    email: string,
    name: string,
    password: string,
}

export interface ClientRegisterDto {
    clientName: string,
    clientSecret: string,
    accessSeconds: number,
    refreshSeconds: number,
    management: boolean,
    fileId: number,
    color: number,
    origin: string,
    loginUrl: string,
    callbackUrl: string,
}

export interface CategoryListDto {
    categories: Array<string>,
}

export interface ContractRegisterDto {
    contractType: string,
    at: Date,
    personNumber: string,
    corporateNumber: string,
    birthDate: Date,
    sexCd: string,
    lastName: string,
    lastNameKana: string,
    firstName: string,
    firstNameKana: string,
    zipcode: string,
    prefectureCd: number,
    localGovernmentCd: number,
    address0: string,
    address1: string,
    address2: string,
    tel: string,
}

export interface ApplicationRegisterDto {
    applicationKey: string,
}

export interface Paging {
    limit: number,
    offset: number,
}

export interface SoExceptionInfo {
    message: string,
    values: number,
}

export interface ManagementApi {

    // ManagementApi
    resetPassword: (mailVerificationId: string, passwordRegisterDto: PasswordRegisterDto) => Promise<void>;
    updateClient: (clientId: string, clientRegisterDto: ClientRegisterDto) => Promise<void>;
    getUser: () => Promise<UserDto>;
    getContract: () => Promise<ContractDto>;
    getMailTemplateForSend: (category: string, mailTemplateId: string) => Promise<MailTemplateForSendDto>;
    registerApplicationManager: (applicationKey: string, applicationManagerRegisterDto: ApplicationManagerRegisterDto) => Promise<void>;
    deleteContract: () => Promise<void>;
    getApplicationManager: (applicationKey: string, personId: number) => Promise<ApplicationManagerDto>;
    applyPassword: (passwordApplyDto: PasswordApplyDto) => Promise<void>;
    applyUser: (userApplyDto: UserApplyDto) => Promise<void>;
    applyApplication: (applicationApplyDto: ApplicationApplyDto) => Promise<void>;
    listApplicationManager: (applicationKey: string, paging?: Paging) => Promise<ApplicationManagerListDto>;
    deleteApplicationManager: (applicationKey: string, personId: number) => Promise<void>;
    getApplicationSummary: () => Promise<ApplicationSummaryDto>;
    deleteApplication: (applicationKey: string) => Promise<void>;
    registerClient: (clientId: string, clientRegisterDto: ClientRegisterDto) => Promise<void>;
    getApplication: (applicationKey: string) => Promise<ApplicationDto>;
    listApplication: (paging?: Paging) => Promise<ApplicationListDto>;
    searchFile: (fileSearchDto: FileSearchDto, paging?: Paging) => Promise<FileListDto>;
    deleteClient: (clientId: string) => Promise<void>;
    listAuthorizationCandidate: (clientId: string, paging?: Paging) => Promise<PersonListDto>;
    listMailCategory: () => Promise<CategoryListDto>;
    listAnnouncementCategory: () => Promise<CategoryListDto>;
    updateUser: (userRegisterDto: UserRegisterDto) => Promise<void>;
    getClient: (clientId: string) => Promise<ClientDto>;
    acceptUser: (mailVerificationId: string, passwordRegisterDto: PasswordRegisterDto) => Promise<void>;
    getExistsApplication: (applicationKey: string) => Promise<ApplicationExistsDto>;
    applyContract: (contractRegisterDto: ContractRegisterDto) => Promise<void>;
    listClient: (paging?: Paging) => Promise<ClientListDto>;
    getAuthorizationCandidate: (clientId: string, personId: number, paging?: Paging) => Promise<RelationListDto>;
}

export const useManagementApi = (context: SoContextType): ManagementApi => {
    const config: AxiosRequestConfig = {
        baseURL: process.env.REACT_APP_API_URL,
    };
    const axiosInstance = axios.create(config);
    axiosInstance.interceptors.request.use(config => {
        context.onRequest();
        return context.useToken().then(token => {
            config.headers.Authorization = `Bearer ${token}`
            return Promise.resolve(config)
        });
    });
    axiosInstance.interceptors.response.use(
        response => {
            context.onResponse();
            return response;
        },
        error => {
            context.onResponse();
            return Promise.reject(error);
        }
    );
    
    return {

        // ManagementApi

        resetPassword: async (mailVerificationId: string, passwordRegisterDto: PasswordRegisterDto): Promise<void> => {
            const path = `/user-password/${mailVerificationId}`;
            const result = await axiosInstance.put(path, passwordRegisterDto, {headers: {'content-type': 'application/json'}});
            return;
        },

        updateClient: async (clientId: string, clientRegisterDto: ClientRegisterDto): Promise<void> => {
            const path = `/client/${clientId}`;
            const result = await axiosInstance.put(path, clientRegisterDto, {headers: {'content-type': 'application/json'}});
            return;
        },

        getUser: async (): Promise<UserDto> => {
            const path = `/user`;
            const result = await axiosInstance.get(path, {});
            return result.data;
        },

        getContract: async (): Promise<ContractDto> => {
            const path = `/contract`;
            const result = await axiosInstance.get(path, {});
            return result.data;
        },

        getMailTemplateForSend: async (category: string, mailTemplateId: string): Promise<MailTemplateForSendDto> => {
            const path = `/mail-for-send/${category}/${mailTemplateId}`;
            const result = await axiosInstance.get(path);
            return result.data;
        },

        registerApplicationManager: async (applicationKey: string, applicationManagerRegisterDto: ApplicationManagerRegisterDto): Promise<void> => {
            const path = `/application-manager/${applicationKey}`;
            const result = await axiosInstance.post(path, applicationManagerRegisterDto, {headers: {'content-type': 'application/json'}});
            return;
        },

        deleteContract: async (): Promise<void> => {
            const path = `/contract`;
            const result = await axiosInstance.delete(path, {});
            return;
        },

        getApplicationManager: async (applicationKey: string, personId: number): Promise<ApplicationManagerDto> => {
            const path = `/application-manager/${applicationKey}/{personId}`;
            const result = await axiosInstance.get(path, {});
            return result.data;
        },

        applyPassword: async (passwordApplyDto: PasswordApplyDto): Promise<void> => {
            const path = `/user-password`;
            const result = await axiosInstance.post(path, passwordApplyDto, {headers: {'content-type': 'application/json'}});
            return;
        },

        applyUser: async (userApplyDto: UserApplyDto): Promise<void> => {
            const path = `/user`;
            const result = await axiosInstance.post(path, userApplyDto, {headers: {'content-type': 'application/json'}});
            return;
        },

        applyApplication: async (applicationApplyDto: ApplicationApplyDto): Promise<void> => {
            const path = `/application`;
            const result = await axiosInstance.post(path, applicationApplyDto, {headers: {'content-type': 'application/json'}});
            return;
        },

        listApplicationManager: async (applicationKey: string, paging?: Paging): Promise<ApplicationManagerListDto> => {
            const path = `/application-manager-list/${applicationKey}`;
            const params: any = {};
            if(paging && paging.limit) params["limit"] = paging.limit;
            if(paging && paging.offset) params["offset"] = paging.offset;
            const result = await axiosInstance.get(path, {params: params, });
            return result.data;
        },

        deleteApplicationManager: async (applicationKey: string, personId: number): Promise<void> => {
            const path = `/application-manager/${applicationKey}/{personId}`;
            const result = await axiosInstance.delete(path, {});
            return;
        },

        getApplicationSummary: async (): Promise<ApplicationSummaryDto> => {
            const path = `/application-summary`;
            const result = await axiosInstance.get(path, {});
            return result.data;
        },

        deleteApplication: async (applicationKey: string): Promise<void> => {
            const path = `/application/${applicationKey}`;
            const result = await axiosInstance.delete(path, {});
            return;
        },

        registerClient: async (clientId: string, clientRegisterDto: ClientRegisterDto): Promise<void> => {
            const path = `/client/${clientId}`;
            const result = await axiosInstance.post(path, clientRegisterDto, {headers: {'content-type': 'application/json'}});
            return;
        },

        getApplication: async (applicationKey: string): Promise<ApplicationDto> => {
            const path = `/application/${applicationKey}`;
            const result = await axiosInstance.get(path, {});
            return result.data;
        },

        listApplication: async (paging?: Paging): Promise<ApplicationListDto> => {
            const path = `/application-list`;
            const params: any = {};
            if(paging && paging.limit) params["limit"] = paging.limit;
            if(paging && paging.offset) params["offset"] = paging.offset;
            const result = await axiosInstance.get(path, {params: params, });
            return result.data;
        },

        searchFile: async (fileSearchDto: FileSearchDto, paging?: Paging): Promise<FileListDto> => {
            const path = `/storage-search`;
            const params: any = {};
            if(paging && paging.limit) params["limit"] = paging.limit;
            if(paging && paging.offset) params["offset"] = paging.offset;
            const result = await axiosInstance.post(path, fileSearchDto, {params: params, headers: {'content-type': 'application/json'}});
            return result.data;
        },

        deleteClient: async (clientId: string): Promise<void> => {
            const path = `/client/${clientId}`;
            const result = await axiosInstance.delete(path, {});
            return;
        },

        listAuthorizationCandidate: async (clientId: string, paging?: Paging): Promise<PersonListDto> => {
            const path = `/authorization-candidate/${clientId}`;
            const params: any = {};
            if(paging && paging.limit) params["limit"] = paging.limit;
            if(paging && paging.offset) params["offset"] = paging.offset;
            const result = await axiosInstance.get(path, {params: params, });
            return result.data;
        },

        listMailCategory: async (): Promise<CategoryListDto> => {
            const path = `/mail-category-list`;
            const result = await axiosInstance.get(path, {});
            return result.data;
        },

        listAnnouncementCategory: async (): Promise<CategoryListDto> => {
            const path = `/announcement-category-list`;
            const result = await axiosInstance.get(path, {});
            return result.data;
        },

        updateUser: async (userRegisterDto: UserRegisterDto): Promise<void> => {
            const path = `/user`;
            const result = await axiosInstance.put(path, userRegisterDto, {headers: {'content-type': 'application/json'}});
            return;
        },

        getClient: async (clientId: string): Promise<ClientDto> => {
            const path = `/client/${clientId}`;
            const result = await axiosInstance.get(path, {});
            return result.data;
        },

        acceptUser: async (mailVerificationId: string, passwordRegisterDto: PasswordRegisterDto): Promise<void> => {
            const path = `/user/${mailVerificationId}`;
            const result = await axiosInstance.patch(path, passwordRegisterDto, {headers: {'content-type': 'application/json'}});
            return;
        },

        getExistsApplication: async (applicationKey: string): Promise<ApplicationExistsDto> => {
            const path = `/application-exists/${applicationKey}`;
            const result = await axiosInstance.get(path, {});
            return result.data;
        },

        applyContract: async (contractRegisterDto: ContractRegisterDto): Promise<void> => {
            const path = `/contract`;
            const result = await axiosInstance.post(path, contractRegisterDto, {headers: {'content-type': 'application/json'}});
            return;
        },

        listClient: async (paging?: Paging): Promise<ClientListDto> => {
            const path = `/client-list`;
            const params: any = {};
            if(paging && paging.limit) params["limit"] = paging.limit;
            if(paging && paging.offset) params["offset"] = paging.offset;
            const result = await axiosInstance.get(path, {params: params, });
            return result.data;
        },

        getAuthorizationCandidate: async (clientId: string, personId: number, paging?: Paging): Promise<RelationListDto> => {
            const path = `/authorization-candidate/${clientId}/${personId}`;
            const params: any = {};
            if(paging && paging.limit) params["limit"] = paging.limit;
            if(paging && paging.offset) params["offset"] = paging.offset;
            const result = await axiosInstance.get(path, {params: params, });
            return result.data;
        },
    }
}
