import React, { useContext } from 'react'
import { SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import 'scss/application.scss';
import { Button, Modal } from 'react-bootstrap';
import useForm, { FormItemGroupType, FormItemType, FormItems, FormValuesType } from 'modules/form';
import validators from 'modules/validators';

type PersonRegisterProps = {
    relation?: number,
    relationName: string,
    onClose: (done: boolean) => void,
};

export const RelationRegister = (props: PersonRegisterProps) => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);

    const actionName = props.relation ? "更新" : "登録";

    const form = useForm([
        new FormItemType({
            name: "relation",
            label: "ID",
            type: "text",
            required: true,
            validators: validators.id,
            initialValue: props.relation ? String(props.relation) : "",
            readOnly: props.relation !== undefined
        }),
        new FormItemType({
            name: "relationName",
            label: "名称",
            type: "text",
            required: true,
            validators: validators.name,
            initialValue: props.relationName,
        }),
    ], (values: FormValuesType) => {
        const exec: Promise<void> = props.relation ?
            context.api.updateRelation(props.relation, {
                relationName: values["relationName"] as string,
            }):
            context.api.registerRelation(parseInt(values["relation"] as string), {
                relationName: values["relationName"] as string,
            });
        exec.then(() => {
            toast.info(`関係を${actionName}しました。`);
            props.onClose(true);
        }).catch(error => {
            toast.error(`関係の${actionName}に失敗しました。`);
            props.onClose(false);
        });
    });

    return (
        <div className={"modal show"} style={{ display: 'block', position: 'initial' }}>
            <Modal show={true} onHide={() => props.onClose(false)} scrollable={false}>
                <Modal.Header closeButton>
                    関係登録
                </Modal.Header>
                <Modal.Body className="m-4">
                    <FormItems { ...form } />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.onClose(false)}>キャンセル</Button>
                    <Button variant="primary" onClick={form.onSubmit}>{actionName}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
