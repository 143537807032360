import React, { useContext } from 'react'
import { MailTemplateDto, MailTemplateIdDto, MailTemplateRegisterDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import 'scss/application.scss';
import { Button, Modal } from 'react-bootstrap';
import useForm, { FormItemGroup, FormItemGroupType, FormItemType, FormItems, FormValuesType } from 'modules/form';
import validators from 'modules/validators';
import { useRelations } from 'modules/relations';

type MailTemplateRegisterProps = {
    category: string,
    mailTemplate?: MailTemplateDto,
    onClose: (done: boolean) => void,
};

export const MailTemplateRegister = (props: MailTemplateRegisterProps) => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);

    const relations = useRelations(true);
    
    const exists = props.mailTemplate !== undefined;
    const actionName = exists ? "更新" : "登録";

    const form = useForm([
        new FormItemGroupType([
            new FormItemType({
                name: "category",
                label: "カテゴリー",
                type: "text",
                required: true,
                validators: validators.name,
                initialValue: exists ? props.category : "",
                readOnly: exists,
            }),
            new FormItemType({
                name: "mailTemplateId",
                label: "ID",
                type: "text",
                required: true,
                validators: validators.name,
                initialValue: exists ? props.mailTemplate.mailTemplateId : "",
                readOnly: exists,
            }),
        ], "id", "識別子"),
        new FormItemType({
            name: "mailTemplateName",
            label: "名称",
            type: "text",
            required: true,
            validators: validators.name,
            initialValue: exists ? props.mailTemplate.mailTemplateName : "",
        }),
        new FormItemGroupType([
            new FormItemType({
                name: "relation",
                label: "関係",
                type: "select",
                required: false,
                initialValue: exists ? props.mailTemplate.relation : null,
                listItem: relations,
                readOnly: exists,
            }),
            new FormItemType({
                name: "verification",
                label: "検証",
                type: "switch",
                initialValue: exists ? props.mailTemplate.verification : false,
            }),
        ], "prefereces", "設定"),
        new FormItemGroupType([
            new FormItemType({
                name: "fromEmail",
                label: "送信元Email",
                type: "text",
                required: true,
                validators: validators.name,
                initialValue: exists ? props.mailTemplate.fromEmail : "",
            }),
            new FormItemType({
                name: "fromName",
                label: "送信元名",
                type: "text",
                required: true,
                validators: validators.name,
                initialValue: exists ? props.mailTemplate.fromName : "",
            }),
        ], "from", "送信元"),
        new FormItemGroupType([
            new FormItemType({
                name: "toEmail",
                label: "送信先Email",
                type: "text",
                required: true,
                validators: validators.name,
                initialValue: exists ? props.mailTemplate.toEmail : "",
            }),
            new FormItemType({
                name: "toName",
                label: "送信先名",
                type: "text",
                required: true,
                validators: validators.name,
                initialValue: exists ? props.mailTemplate.toName : "",
            }),
        ], "to", "送信先"),
        new FormItemType({
            name: "subject",
            label: "題名",
            type: "text",
            required: true,
            validators: validators.name,
            initialValue: exists ? props.mailTemplate.subject : "",
        }),
        new FormItemType({
            name: "content",
            label: "内容",
            type: "textArea",
            required: true,
            initialValue: exists ? props.mailTemplate.content : "",
        }),
    ], (values: FormValuesType) => {
        const mailTemplateRegisterDto: MailTemplateRegisterDto = {
            mailTemplateName: values["mailTemplateName"] as string,
            verification: values["verification"] as boolean,
            fromEmail: values["fromEmail"] as string,
            fromName: values["fromName"] as string,
            toEmail: values["toEmail"] as string,
            toName: values["toName"] as string,
            subject: values["subject"] as string,
            content: values["content"] as string,
        };
        const exec: Promise<void | MailTemplateIdDto> = props.mailTemplate ? 
            context.api.updateMailTemplate(props.category, props.mailTemplate.mailTemplateId, mailTemplateRegisterDto):
            context.api.registerMailTemplate(values["category"] as string, values["mailTemplateId"] as string, values["relation"] as number | null, mailTemplateRegisterDto);
        exec.then(() => {
            toast.info(`メールテンプレートを${actionName}しました。`);
            props.onClose(true);
        }).catch(error => {
            toast.error(`メールテンプレートの${actionName}に失敗しました。`);
            props.onClose(false);
        });
    });

    return (
        <div className={"modal show"} style={{ display: 'block', position: 'initial' }}>
            <Modal show={true} size="lg" onHide={() => props.onClose(false)} scrollable={false}>
                <Modal.Header closeButton>
                    メールテンプレート{actionName}
                </Modal.Header>
                <Modal.Body className="m-4">
                    <FormItems { ...form } />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.onClose(false)}>キャンセル</Button>
                    <Button variant="primary" onClick={form.onSubmit}>{actionName}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
