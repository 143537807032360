import React, { useContext } from 'react'
import { MailTemplateDto, RelationDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import 'scss/application.scss';
import { Button, Modal } from 'react-bootstrap';

type MailTemplateDeleteProps = {
    category: string,
    mailTemplate: MailTemplateDto,
    onClose: (done: boolean) => void,
};

export const MailTemplateDelete = (props: MailTemplateDeleteProps) => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);

    const onDelete = () => {
        context.api.deleteMailTemplate(props.category, props.mailTemplate.mailTemplateId)
        .then(() => {
            toast.info("メールテンプレートを削除しました。");
            props.onClose(true);
        })
        .catch(error => {
            toast.error("メールテンプレートの削除に失敗しました。");
            props.onClose(false);
        });
    };

    return (
        <Modal show={true} onHide={() => props.onClose(false)} scrollable={false}>
            <Modal.Header closeButton>
            メールテンプレートを削除しますがよろしいですか？
            </Modal.Header>
            <Modal.Body className="m-4">
                <p>{props.category}</p>
                <p>{props.mailTemplate.mailTemplateId}</p>
                <p>{props.mailTemplate.mailTemplateName}</p>
                <p>{props.mailTemplate.subject}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.onClose(false)}>いいえ</Button>
                <Button variant="primary" onClick={onDelete}>はい</Button>
            </Modal.Footer>
        </Modal>
    );
}
