import React, { useContext } from 'react'
import { AnnouncementDto, AnnouncementRegisterDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import 'scss/application.scss';
import { Button, Modal } from 'react-bootstrap';
import useForm, { FormItemGroup, FormItemGroupType, FormItemType, FormItems, FormValuesType, RegistrationFile } from 'modules/form';
import validators from 'modules/validators';
import { useRelations } from 'modules/relations';

type AnnouncementRegisterProps = {
    category: string,
    announcement?: AnnouncementDto,
    onClose: (done: boolean) => void,
};

export const AnnouncementRegister = (props: AnnouncementRegisterProps) => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);

    const relations = useRelations(true);
    
    const exists = props.announcement !== undefined;
    const actionName = exists ? "更新" : "登録";

    const form = useForm([
        new FormItemGroupType([
            new FormItemType({
                name: "category",
                label: "カテゴリー",
                type: "text",
                required: true,
                validators: validators.name,
                initialValue: exists ? props.category : "",
                readOnly: exists,
            }),
            new FormItemType({
                name: "relation",
                label: "関係",
                type: "select",
                required: false,
                initialValue: exists ? props.announcement.relation : null,
                listItem: relations,
                readOnly: exists,
            }),
        ], "id", "識別子"),
        new FormItemType({
            name: "announcementName",
            label: "名称",
            type: "text",
            required: true,
            validators: validators.name,
            initialValue: exists ? props.announcement.announcementName : "",
        }),
        new FormItemGroupType([
            new FormItemType({
                name: "publishTime",
                label: "公開日時",
                type: "dateTime",
                required: true,
                initialValue: exists ? props.announcement.publishTime : "",
            }),
            new FormItemType({
                name: "expire",
                label: "期限",
                type: "dateTime",
                required: false,
                initialValue: exists ? props.announcement.expire : "",
            }),
        ], "publish", "公開"),
        new FormItemType({
            name: "passCode",
            label: "ファイル",
            type: "file",
            required: false,
            initialValue: exists ? props.announcement.fileId : null,
        }),
        new FormItemType({
            name: "content",
            label: "内容",
            type: "textArea",
            required: true,
            initialValue: exists ? props.announcement.content : "",
        }),
    ], (values: FormValuesType) => {
        const announcementRegisterDto: AnnouncementRegisterDto = {
            announcementName: values["announcementName"] as string,
            content: values["content"] as string,
            publishTime: values["publishTime"] as string,
            expire: values["expire"] as string,
            passCode: (values["passCode"] as RegistrationFile).passCode,
        };
        const exec: Promise<void> = props.announcement ? 
            context.api.updateAnnouncement(props.announcement.announcementId, announcementRegisterDto):
            context.api.registerAnnouncement(values["category"] as string, values["relation"] as number | null, announcementRegisterDto);
        exec.then(() => {
            toast.info(`公告を${actionName}しました。`);
            props.onClose(true);
        }).catch(error => {
            toast.error(`公告の${actionName}に失敗しました。`);
            props.onClose(false);
        });
    });

    return (
        <Modal show={true} size="lg" onHide={() => props.onClose(false)} scrollable={false}>
            <Modal.Header closeButton>
            公告{actionName}
            </Modal.Header>
            <Modal.Body className="m-4">
                <FormItems { ...form } />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.onClose(false)}>キャンセル</Button>
                <Button variant="primary" onClick={form.onSubmit}>{actionName}</Button>
            </Modal.Footer>
        </Modal>
    );
}
