import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AnnouncementDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import { CategoryListDto, useManagementApi } from 'modules/managementApi';
import 'scss/application.scss';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { toDateTimeString } from 'modules/utils';
import { AnnouncementRegister } from './AnnouncementRegister';
import { AnnouncementDelete } from './AnnouncementDelete';

type AnnouncementForRegister = {
    category: string,
    announcement?: AnnouncementDto,
};

export const AnnouncementList = () => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);
    const params = useParams();
    const navigate = useNavigate();
    const managementApi = useManagementApi(context);

    const [category, setCategory] = useState<string | undefined>(undefined);
    const [categories, setCategories] = useState<Array<string> | undefined>(undefined);
    const [announcements, setAnnouncements] = useState<Array<AnnouncementDto> | undefined>(undefined);
    const [forRegister, setForRegister] = useState<AnnouncementForRegister>(undefined);
    const [forDelete, setForDelete] = useState<AnnouncementDto | undefined>(undefined);

    const categoryRef = useRef(null);

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if(categories) {
            console.log(categories)
            if(category) {
                let exists = false;
                for (let i = 0; i < categories.length; i++) {
                    if(categories[i] === category) {
                        exists = true;
                        break;
                    }
                }
                if(!exists) {
                    setCategory(categories[0]);
                }
            } else {
                setCategory(categories[0]);
            }
        } else {
            setCategory(undefined);
        }
    }, [categories]);

    useEffect(() => {
        if(category) {
            console.log(category)
            context.api.listAnnouncement(category, null, false)
            .then(announcementListDto => {
                if(0 < announcementListDto.announcements.length) {
                    setAnnouncements(announcementListDto.announcements);
                } else if(!announcements) {
                    setAnnouncements(undefined);
                }
            })
            .catch(error => {
                toast.error("公告リストの取得に失敗しました。");
                setAnnouncements(undefined);
            });
        } else if(announcements) {
            setAnnouncements(undefined);
        }
    }, [category]);

    const load = () => {
        managementApi.listAnnouncementCategory()
        .then(categoryListDto => {
            if(categoryListDto.categories.length == 0) {
                setCategories(undefined);
            } else {
                setCategories(categoryListDto.categories);
            }
        })
        .catch(error => {
            toast.error("カテゴリーリストの取得に失敗しました。");
            setCategories(undefined);
        })
    };

    const onClose = (done: boolean) => {
        if(forRegister) {
            setForRegister(undefined);
        }
        if(forDelete) {
            setForDelete(undefined);
        }
        if(done) {
            load();
        }
    };

    return (
        <div>
            <h3 className="py-4">{`${context.user.relation_name} 公告リスト`}</h3>
            {categories && <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">カテゴリー</InputGroup.Text>
                <Form.Select id="category" name="category" ref={categoryRef} onChange={() => setCategory(categories[categoryRef.current.selectedIndex])}>
                    {categories.map((c, index) => 
                        <option key={`category-${index}`} value={index} selected={c === category}>{c}</option>
                    )}
                </Form.Select>
            </InputGroup>}
            <div className="d-flex flex-row-reverse">
                <Button variant="outline-secondary" onClick={() => setForRegister({category: category ? category : ""})}>
                    <i className="bi bi-plus"></i>
                </Button>
            </div>
            {(categories && !announcements) && (
                <div className="col col-12 d-flex justify-content-center py-5">
                    <span>登録されていません。</span>
                </div>
            )}
            {(categories && announcements) &&
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">名称</th>
                            <th scope="col" className="text-center">検証</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {announcements.map((announcement, index) =>
                            <tr key={announcement.announcementId}>
                                <td scope="col" className="text-truncate">{announcement.announcementName}</td>
                                <td scope="col" className="text-truncate">{toDateTimeString(announcement.publishTime)}</td>
                                <td scope="col" className="text-truncate">{toDateTimeString(announcement.expire)}</td>
                                <td scope="col" className="text-truncate">
                                    <Button variant="outline-secondary" className="mx-2" onClick={() => setForRegister({
                                        category: announcement.category,
                                        announcement: announcement
                                    })}>
                                        <i className="bi bi-pencil"></i>
                                    </Button>
                                    <Button variant="outline-secondary" className="mx-2" onClick={() => setForDelete(announcement)}>
                                        <i className="bi bi-trash"></i>
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
            <div className="d-flex justify-content-center py-2">
                <Link to={`/`}>
                    <span>戻る</span>
                </Link>
            </div>
            <div className={"modal show"} style={{ display: 'block', position: 'initial' }}>
                {forRegister && <AnnouncementRegister { ...forRegister } onClose={onClose} />}
                {forDelete && <AnnouncementDelete category={categories[categoryRef.current.selectedIndex]} announcement={forDelete} onClose={onClose} />}
            </div>
        </div>
    );
}
