import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PersonDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import { ClientDto, useManagementApi } from 'modules/managementApi';
import 'scss/application.scss';
import { Button } from 'react-bootstrap';
import { PersonRegister } from './PersonRegister';
import { PersonDelete } from './PersonDelete';

type PersonForRegister = {
    personId?: number,
    name: string,
    email: string,
    password?: string,
};

export const PersonList = () => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);
    const params = useParams();
    const navigate = useNavigate();
    const managementApi = useManagementApi(context);

    const [client, setClient] = useState<ClientDto | undefined>(undefined);
    const [persons, setPersons] = useState<Array<PersonDto> | undefined>(undefined);
    const [forRegister, setForRegister] = useState<PersonForRegister>(undefined);
    const [forDelete, setForDelete] = useState<PersonDto | undefined>(undefined);

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
    }, [forRegister]);

    const load = () => {
        context.api.listPerson(true)
        .then(personListDto => {
            setPersons(personListDto.persons);
        })
        .catch(error => {
            toast.error("個人リストの取得に失敗しました。");
        })
    };

    const onClose = (done: boolean) => {
        if(forRegister) {
            setForRegister(undefined);
        }
        if(forDelete) {
            setForDelete(undefined);
        }
        if(done) {
            load();
        }
    };

    return (
        <div>
            <h3 className="py-4">{`${context.user.relation_name} ${client === undefined ? " " : client.clientName} 個人リスト`}</h3>
            <div className="d-flex flex-row-reverse">
                <Button variant="outline-secondary" onClick={() => setForRegister({email: "", name: "", password: ""})}>
                    <i className="bi bi-plus"></i>
                </Button>
            </div>
            {(persons !== undefined && persons.length === 0) && (
                <div className="col col-12 d-flex justify-content-center py-5">
                    <span>登録されていません。</span>
                </div>
            )}
            {(persons !== undefined && 0 < persons.length) &&
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">名前</th>
                            <th scope="col">メールアドレス</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {persons.map((person, index) =>
                            <tr key={person.personId}>
                                <td scope="col">{person.personId}</td>
                                <td scope="col" className="text-truncate">{person.name}</td>
                                <td scope="col" className="text-truncate">{person.email}</td>
                                <td scope="col" className="text-truncate">
                                    <Button variant="outline-secondary" className="mx-2" onClick={() => setForRegister(person)}>
                                        <i className="bi bi-pencil"></i>
                                    </Button>
                                    <Button variant="outline-secondary" className="mx-2" onClick={() => setForDelete(person)}>
                                        <i className="bi bi-trash"></i>
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
            <div className="d-flex justify-content-center py-2">
                <Link to={`/`}>
                    <span>戻る</span>
                </Link>
            </div>
            <div className={"modal show"} style={{ display: 'block', position: 'initial' }}>
                {forRegister && <PersonRegister { ...forRegister } onClose={onClose} />}
                {forDelete && <PersonDelete person={forDelete} onClose={onClose} />}
            </div>
        </div>
    );
}
