import React, { useContext } from 'react'
import { PersonDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import 'scss/application.scss';
import { Button, Modal } from 'react-bootstrap';

type PersonDeleteProps = {
    person: PersonDto,
    onClose: (done: boolean) => void,
};

export const PersonDelete = (props: PersonDeleteProps) => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);

    const onDelete = () => {
        context.api.deletePerson(props.person.personId)
        .then(() => {
            toast.info("個人を削除しました。");
            props.onClose(true);
        })
        .catch(error => {
            toast.error("個人の削除に失敗しました。");
            props.onClose(false);
        });
    };

    return (
        <Modal show={true} onHide={() => props.onClose(false)} scrollable={false}>
            <Modal.Header closeButton>
            個人を削除しますがよろしいですか？
            </Modal.Header>
            <Modal.Body className="m-4">
                <p>{props.person.name}</p>
                <p>{props.person.email}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.onClose(false)}>いいえ</Button>
                <Button variant="primary" onClick={onDelete}>はい</Button>
            </Modal.Footer>
        </Modal>
    );
}
