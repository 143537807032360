import { ValidatorType } from "./form";

const maxLength = (value: string, maxLength: number) => {
    return value.length <= maxLength ? null : `${maxLength}文字までです。`;
}

const fixedLength = (value: string, fixedLength: number) => {
    return value.length === fixedLength ? null : "" + fixedLength + "文字で入力してください。";
}

const email = (value: string) => {
    return value.match(/.+@.+\..+/) ? null : "形式が正しくありません。";
}

const hiragana = (value: string) => {
    return value.match(/^[ぁ-んー　]*$/) ? null : "ひらがなのみで入力してください。";   
}

const numeric = (value: string) => {
    return value.match(/^[0-9]*$/) ? null : "数字のみで入力してください。";   
}

const numericRange = (value: string, min: number | null, max: number | null) => {
    const n = parseInt(value);
    if(min !== null && n < min) {
        return `${min}${max === null ? "以上" : "から" + String(max) + "の範囲"}で入力してください。`;
    } else if(max !== null && max < n) {
        return `${min === null ? String(max) + "以上" : String(min) + "から" + String(max) + "の範囲"}で入力してください。`;
    } else {
        return null;   
    }
}

const zipCode = (value: string) => {
    return value.match(/^\d{3}-?\d{4}$/) ? null : "形式が正しくありません。";
}

const tel = (value: string) => {
    return value.match(/^0\d{1,3}-?\d{1,4}-?\d{4}$/) ? null : "形式が正しくありません。";
}

const maxLengthOfName: number = 64;
const maxLengthOfAddress: number = 128;
const maxLengthOfContents: number = 4000;

const validators: {[key: string]: Array<ValidatorType>} = {
    id: [
        value => numeric(value),
        value => numericRange(value, 1, null),
    ],
    name: [
        value => maxLength(value, maxLengthOfName),
    ],
    lastName: [
        value => maxLength(value, maxLengthOfName),
    ],
    firstName: [
        value => maxLength(value, maxLengthOfName),
    ],
    lastNameKana: [
        value => hiragana(value),
        value => maxLength(value, maxLengthOfName),
    ],
    firstNameKana: [
        value => hiragana(value),
        value => maxLength(value, maxLengthOfName),
    ],
    corporateNumber: [
        value => numeric(value),
        value => fixedLength(value, 13),
    ],
    email: [
        value => email(value),
    ],
    tel1: [
        value => tel(value),
    ],
    zipCd: [
        value => zipCode(value),
    ],
    address0: [
        value => maxLength(value, maxLengthOfAddress),
    ],
    address1: [
        value => maxLength(value, maxLengthOfAddress),
    ],
    address2: [
        value => maxLength(value, maxLengthOfAddress),
    ],
    companyName: [
        value => maxLength(value, maxLengthOfName),
    ],
    divisionName: [
        value => maxLength(value, maxLengthOfName),
    ],
    contents: [
        value => maxLength(value, maxLengthOfContents),
    ],
    message: [
        value => maxLength(value, maxLengthOfContents),
    ],
    relationName: [
        value => maxLength(value, 32),
    ],
    applicationKey: [
        value => maxLength(value, 24),
    ],
    clientId: [
        value => maxLength(value, 24),
    ],
    validSeconds: [
        value => numeric(value),
        value => numericRange(value, 30, 86400),
    ],
};

export default validators;