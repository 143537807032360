import { useContext, useEffect, useState } from "react";
import { SoContext, SoContextType } from "react-sonare";
import { ToastContext, ToastContextType } from "./toast";
import { CodeType } from "./form";
import { toCodeArray } from "./utils";

const noRelation: CodeType = {code: null, name: "（指定なし）"};

export function useRelations(withNone: boolean = false): Array<CodeType> {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);

    const [relations, setRelations] = useState<Array<CodeType>>(withNone ? [noRelation] : []);

    const load: () => Promise<void> = () => {
        return new Promise<void>((resolve, reject) => {
            context.api.listRelation(true)
            .then(relationListDto => {
                setRelations(toCodeArray(relationListDto.relations, r => r.relation, r => r.relationName, withNone ? [noRelation] : []));
                resolve();
            })
            .catch(error => {
                toast.error("関係リストの取得に失敗しました。");
                reject(error);
            });
        });
    };

    useEffect(() => {
        context.api.listRelation(true)
        .then(relationListDto => {
            setRelations(toCodeArray(relationListDto.relations, r => r.relation, r => r.relationName, withNone ? [noRelation] : []));
        })
        .catch(error => {
            toast.error("関係リストの取得に失敗しました。");
        });
    }, []);

    return relations;
}