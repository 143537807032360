import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthorizationDto, PersonDto, RelationDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import { ClientDto, useManagementApi } from 'modules/managementApi';
import 'scss/application.scss';
import { Button, ListGroup, Modal } from 'react-bootstrap';

type AuthorizationDeleteProps = {
    client: ClientDto
    authorization: AuthorizationDto,
    onClose: (done: boolean) => void,
};

export const AuthorizationDelete = (props: AuthorizationDeleteProps) => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);

    const onDelete = () => {
        context.api.deleteAuthorization(props.client.clientId, props.authorization.relation, props.authorization.personId)
        .then(() => {
            props.onClose(true);
        })
        .catch(error => {
            toast.error("認可の削除に失敗しました。");
            props.onClose(false);
        });
    };

    return (
        <Modal show={true} onHide={() => props.onClose(false)} scrollable={false}>
            <Modal.Header closeButton>
                認可を削除しますがよろしいですか？
            </Modal.Header>
            <Modal.Body className="m-4">
                <p>{props.client.clientName}</p>
                <p>{props.authorization.name}</p>
                <p>{props.authorization.relationName}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.onClose(false)}>いいえ</Button>
                <Button variant="primary" onClick={onDelete}>はい</Button>
            </Modal.Footer>
        </Modal>
    );
}
