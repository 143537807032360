import React, { useContext, useEffect, useState } from 'react'
import { MailTemplateDto, MailTemplateIdDto, MailTemplateRegisterDto, MailVerificationRegisterDto, MailboxDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import 'scss/application.scss';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import useForm, { FormItemGroup, FormItemGroupType, FormItemType, FormItems, FormValuesType } from 'modules/form';
import validators from 'modules/validators';
import { useRelations } from 'modules/relations';
import { useParams } from 'react-router-dom';
import { MailTemplateForSendDto, useManagementApi } from 'modules/managementApi';
import { Link } from 'react-router-dom';
import { toDateTimeString, withBr } from 'modules/utils';

export const MailSend = () => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);
    const params = useParams();
    const managementApi = useManagementApi(context);

    const [mailTemplateForSend, setMailTemplateForSend] = useState<MailTemplateForSendDto | undefined>(undefined);
    const [mailboxes, setMailboxes] = useState<Array<MailboxDto> | undefined>(undefined);
    const [forSend, setForSend] = useState<boolean>(false);

    useEffect(() => {
        loadMailTemplateForSend();
    }, []);

    useEffect(() => {
        if(mailTemplateForSend) {
            loadMailBoxes();
        }
    }, [mailTemplateForSend]);

    const loadMailTemplateForSend = () => {
        managementApi.getMailTemplateForSend(params.category, params.mailTemplateId)
        .then(mailTemplateForSendDto => {
            setMailTemplateForSend(mailTemplateForSendDto);
        })
        .catch(error => {
            toast.error("送信用メールテンプレートの取得に失敗しました。");
        });
    };

    const loadMailBoxes = () => {
        context.api.listMailbox(params.category, params.mailTemplateId)
        .then(mailBoxListDto => {
            setMailboxes(mailBoxListDto.mailboxes);
        })
        .catch(error => {
            toast.error("メールボックスリストの取得に失敗しました。");
        });
    };

    const onClose = (done: boolean) => {
        setForSend(false);
        if(done) {
            loadMailBoxes();
        }
    };

    return (
        <div>
            <h3 className="py-4"> メール送信</h3>

            {mailTemplateForSend && (<>
                <Row className="mb-3">
                    <Col xs={4}>ID</Col>
                    <Col xs={8}>{mailTemplateForSend.mailTemplateId}</Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={4}>名称</Col>
                    <Col xs={8} className="text-truncate">{mailTemplateForSend.mailTemplateName}</Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={4}>送信元</Col>
                    <Col xs={4} className="text-truncate">{mailTemplateForSend.fromEmail}</Col>
                    <Col xs={4} className="text-truncate">{mailTemplateForSend.fromName}</Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={4}>送信先</Col>
                    <Col xs={4} className="text-truncate">{mailTemplateForSend.toEmail}</Col>
                    <Col xs={4} className="text-truncate">{mailTemplateForSend.toName}</Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={4}>内容</Col>
                    <Col xs={8} className="text-truncate">{withBr(mailTemplateForSend.content)}</Col>
                </Row>
                <div className="d-flex justify-content-center py-2">
                    <button className="btn btn-primary" onClick={() => setForSend(true)}>メール送信</button>
                </div>
            </>)}
            {(mailboxes && mailboxes.length == 0) && (
                <div className="col col-12 d-flex justify-content-center py-5">
                    <span>送信されていません。</span>
                </div>
            )}
            {(mailboxes && 0 < mailboxes.length) && (<>
                <h4 className="mt-4">送信履歴</h4>
                <Row className="justify-content-md-center">
                    <Col lg={8}>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">送信日時</th>
                                    <th scope="col">送信件数</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mailboxes.map((mailbox, index) =>
                                    <tr key={`mailbox-${index}`}>
                                        <td scope="col" className="text-truncate">{toDateTimeString(mailbox.registerTime)}</td>
                                        <td scope="col" className="text-truncate">{mailbox.countTarget}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </>)}
            <div className="d-flex justify-content-center py-2">
                <Link to={"/mail"}>
                    <span>戻る</span>
                </Link>
            </div>
            <div className={"modal show"} style={{ display: 'block', position: 'initial' }}>
                {forSend && <SendDialog category={params.category} mailTemplateForSend={mailTemplateForSend} onClose={onClose} />}
            </div>
        </div>
    );
};

type SendDialogProps = {
    category: string,
    mailTemplateForSend: MailTemplateForSendDto,
    onClose: (done: boolean) => void,
};

const SendDialog = (props: SendDialogProps) => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);

    const items: Array<FormItemType> = props.mailTemplateForSend.verification ? [
        new FormItemType({
            name: "email",
            label: "メールアドレス",
            type: "text",
            required: true,
            initialValue: context.user.email,
        })] : [];
    for (const variable of props.mailTemplateForSend.variables) {
        if(!props.mailTemplateForSend.verification || (variable !== "email" && variable !== "mailVerificationId")) {
            items.push(new FormItemType({
                name: variable,
                label: variable,
                type: "text",
                required: true,
                initialValue: "",
            }));
        }
    };

    const form = useForm(items, values => {
        const attribute: {[key: string]: string} = {};
        for (const variable of props.mailTemplateForSend.variables) {
            attribute[variable] = values[variable] as string;
        }
        if(props.mailTemplateForSend.verification) {
            const email = attribute["email"];
            context.api.registerMailVerification(props.category, props.mailTemplateForSend.mailTemplateId, {email: email, attribute: attribute})
            .then(mailboxIdDto => {
                toast.info(`検証メールを送信しました。`);
                props.onClose(true);
            })
            .catch(error => {
                toast.error(`検証メールの送信に失敗しました。`);
                props.onClose(false);
            })
        } else {
            context.api.registerMailbox(props.category, props.mailTemplateForSend.mailTemplateId, {attributes: [attribute]})
            .then(mailboxIdDto => {
                toast.info(`メールを送信しました。`);
                props.onClose(true);
            })
            .catch(error => {
                toast.error(`メールの送信に失敗しました。`);
                props.onClose(false);
            })
        }
    });

    return (
        <div className={"modal show"} style={{ display: 'block', position: 'initial' }}>
            <Modal show={true} size="lg" onHide={() => props.onClose(false)} scrollable={false}>
                <Modal.Header closeButton>
                    メール送信
                </Modal.Header>
                <Modal.Body className="m-4">
                    {props.mailTemplateForSend.variables.length == 0 ? 
                        <span>（テンプレート変数は使用されていません）</span>:
                        <FormItems { ...form } />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.onClose(false)}>キャンセル</Button>
                    <Button variant="primary" onClick={form.onSubmit}>送信</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};