import React from "react";
import { CodeType } from "./form";

export const toDateString = (date: string | Date | null) => {
    if(date) {
        if(date instanceof Date) {
        } else {
            date = new Date(date);
        }
        const year = date.getFullYear().toString().padStart(4, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    } else {
        return "";
    }
};

export const toDateTimeString = (date: string | Date | null) => {
    if(date) {
        if(date instanceof Date) {
        } else {
            date = new Date(date);
        }
        const year = date.getFullYear().toString().padStart(4, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');

        return `${year}-${month}-${day} ${hour}:${minute}`;
    } else {
        return "";
    }
};

export function toCodeArray<T>(array: Array<T>, toKey: (t: T) => string | number | null, toValue: (t: T) => string, initial: Array<CodeType> = []): Array<CodeType> {
    const codes: Array<CodeType> = initial;

    array.forEach((t: T) => {
        codes.push({
            code: toKey(t),
            name: toValue(t),
        });
    });

    return codes;
}

export function withBr(content: string) {
    const texts = content.split("\n").map((text, index) => {
        // <></> は key を設定できないので、<React.Fragment /> を使う
        return (
            <React.Fragment key={index}>{text}<br /></React.Fragment>
        );
    });
    return <div>{texts}</div>
}