import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { SoContext, SoContextType } from 'react-sonare';
import { useManagementApi } from 'modules/managementApi';
import { ToastContext, ToastContextType } from 'modules/toast';
import 'scss/application.scss';

const Dashboard = () => {
    const context: SoContextType = useContext(SoContext);
    const navigate = useNavigate();

    return (
        <div>
            <h3>{context.user.relation_name}</h3>
            <div className="row">
                <div className="col col-md-6">
                    <button type="button"  style={{width: "100%"}}className="btn btn-outline-primary m-2 p-4" onClick={() => navigate("/relation")}>関係</button>
                </div>
                <div className="col col-md-6">
                    <button type="button"  style={{width: "100%"}}className="btn btn-outline-primary m-2 p-4" onClick={() => navigate("/person")}>個人</button>
                </div>
                <div className="col col-md-6">
                    <button type="button" style={{width: "100%"}} className="btn btn-outline-primary m-2 p-4" onClick={() => navigate("/client")}>クライアント</button>
                </div>
                <div className="col col-md-6">
                    <button type="button"  style={{width: "100%"}}className="btn btn-outline-primary m-2 p-4" onClick={() => navigate("/storage")}>ストレージ</button>
                </div>
                <div className="col col-md-6">
                    <button type="button"  style={{width: "100%"}}className="btn btn-outline-primary m-2 p-4" onClick={() => navigate("/mail")}>メール</button>
                </div>
                <div className="col col-md-6">
                    <button type="button"  style={{width: "100%"}}className="btn btn-outline-primary m-2 p-4" onClick={() => navigate("/announcement")}>公告</button>
                </div>
                <div className="col col-md-6">
                    <button type="button"  style={{width: "100%"}}className="btn btn-outline-primary m-2 p-4">チャット</button>
                </div>
                <div className="col col-md-6">
                    <button type="button"  style={{width: "100%"}}className="btn btn-outline-primary m-2 p-4">ログ</button>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
