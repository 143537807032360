import React, { useContext } from 'react';
import { Router, Routes, Route, BrowserRouter, Outlet } from 'react-router-dom';
import { SoContext, SoContextType } from 'react-sonare';
import { LoadingContainer, LoadingContext } from 'modules/loading';
import { ToastContainer } from 'modules/toast';
import { SoContainer } from 'react-sonare';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Login from 'components/Login';
import NotFound from 'components/NotFound';
import Dashboard from 'components/Dashboard';
import { ClientList, ClientRegister } from 'components/client';
import { AuthorizationList } from './authorization';
import { PersonList } from './person';
import { RelationList } from './relation';
import { FileSearch } from './strage';
import { MailSend, MailTemplateList } from './mail';
import { AnnouncementList } from './announcement';

const Template: React.FC = () => {
    return (
        <>
            <Header/>
            <div className='container py-2'>
                <Outlet />
            </div>
            <Footer />
        </>
    );
};

const SiteRoutes: React.FC = () => {
    const context: SoContextType = useContext(SoContext);
    return context.prepared ?
        <BrowserRouter>
            {context.user ?
            <Routes>
                <Route path='/' element={<Template />}>
                    <Route index element={<Dashboard />} />
                    <Route path="/client" element={<ClientList />} />
                    <Route path="/client-register" element={<ClientRegister />} />
                    <Route path="/client-edit/:clientId" element={<ClientRegister />} />
                    <Route path="/authorization/:clientId" element={<AuthorizationList />} />
                    <Route path="/relation" element={<RelationList />} />
                    <Route path="/person" element={<PersonList />} />
                    <Route path="/storage" element={<FileSearch />} />
                    <Route path="/mail" element={<MailTemplateList />} />
                    <Route path="/mail/:category" element={<MailTemplateList />} />
                    <Route path="/mail/:category/:mailTemplateId" element={<MailSend />} />
                    <Route path="/announcement" element={<AnnouncementList />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes> :
            <Routes>
                <Route path='/' element={<Template />}>
                    <Route index element={<Login />} />
                    <Route path="*" element={<Login />} />
                </Route>
            </Routes>}
        </BrowserRouter> :
        <div><p>準備中</p></div>
};

const Main: React.FC = () => {
    const loading = useContext(LoadingContext);

    return (
        <SoContainer
            baseUrl={process.env.REACT_APP_SONARE_API_URL}
            applicationKey={process.env.REACT_APP_SONARE_APPLICATION_KEY}
            clientId={process.env.REACT_APP_SONARE_CLIENT_ID}
            clientSecret={process.env.REACT_APP_SONARE_CLIENT_SECRET}
            onRequest={loading.invoke}
            onResponse={loading.revoke}
        >
            <SiteRoutes />
        </SoContainer>
    )
};

const App: React.FC = () => {
    window.localStorage.removeItem("refreshToken");
    return (
        <ToastContainer width={280} height={64} padding={4} inetrval={4000}>
            <LoadingContainer size={2.5}>
                <Main />
            </LoadingContainer>
        </ToastContainer>
    );
};

export default App;
