import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthorizationDto, PersonDto, RelationDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import { ClientDto, useManagementApi } from 'modules/managementApi';
import 'scss/application.scss';
import { Button, ListGroup, Modal } from 'react-bootstrap';

type AuthorizationCandidateType = {
    person: PersonDto,
    relations: Array<RelationDto>,
};

type AuthorizationRegisterProps = {
    client: ClientDto
    onClose: (done: boolean) => void,
};

export const AuthorizationRegister = (props: AuthorizationRegisterProps) => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);
    const managementApi = useManagementApi(context);

    const [persons, setPersons] = useState<Array<PersonDto> | undefined>(undefined);
    const [authorizationCandidate, setAuthorizationCandidate] = useState<AuthorizationCandidateType | undefined>(undefined);

    useEffect(() => {
        managementApi.listAuthorizationCandidate(props.client.clientId)
        .then(personListDto => {
            setAuthorizationCandidate(undefined);
            setPersons(personListDto.persons);
        })
        .catch(error => {
            toast.error("認可候補リストの取得に失敗しました。");
        });
    }, []);

    const onPerson = (person: PersonDto) => {
        managementApi.getAuthorizationCandidate(props.client.clientId, person.personId)
        .then(relationListDto => {
            setAuthorizationCandidate({
                person: person,
                relations: relationListDto.relations,
            });
        })
        .catch(error => {
            toast.error("認可候補の取得に失敗しました。");
        });
    };

    const onRegister = (relation: number) => {
        context.api.registerAuthorization(props.client.clientId, relation, authorizationCandidate.person.personId)
        .then(() => {
            props.onClose(true);
        })
        .catch(error => {
            toast.error("認可の登録に失敗しました。");
            props.onClose(false);
        });
    };
    
    return (
        <Modal show={true} onHide={() => props.onClose(false)} scrollable={true}>
            <Modal.Header closeButton>
                {authorizationCandidate ? `${authorizationCandidate.person.name}との関係` : "認可を付与する個人"}を選択してください
            </Modal.Header>
            <Modal.Body className="m-4">
                {persons && !authorizationCandidate && <ListGroup>
                    {persons.map((person) => (
                        <ListGroup.Item className="list-group-item-action" onClick={() => onPerson(person)}>
                            {person.name}
                        </ListGroup.Item>
                    ))}
                </ListGroup>}
                {persons && authorizationCandidate && <ListGroup>
                    {authorizationCandidate.relations.map((relation) => (
                        <ListGroup.Item className="list-group-item-action" onClick={() => onRegister(relation.relation)}>
                            {relation.relation === authorizationCandidate.person.personId ? "（指定なし）" : relation.relationName}
                        </ListGroup.Item>
                    ))}
                </ListGroup>}
            </Modal.Body>
        </Modal>
    );
}
