import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { OidcApi, SoContext, SoContextType, useOidcApi } from 'react-sonare';
import useForm, { FormValuesType } from 'modules/form';
import axios from 'axios'
import { ToastContext, ToastContextType } from 'modules/toast';
import { stringify } from 'querystring';

interface LoginFormType extends FormValuesType {
    email: string | null;
    password: string | null;
}

const Login = () => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);

    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);
    const code = query.get("code");
    const state = query.get("state");

    const onLogin = (code: string) => {
        context.login(code);
        toast.info("ログインしました。")
        navigate("/");
    };

    let first = true;
    useEffect(() => {
        if(first) {
            first = false;
            if(code) {
                context.loggedin(code)
                .then(() => {
                    toast.info("ログインしました。")
                    navigate("/");
                })
                .catch(error => {
                    toast.error("ログインに失敗しました。")
                    navigate("/");
                });
            } else {
                context.login(`${process.env.REACT_APP_ORIGIN}/contract/login`);
            }
        }
    }, []);

    return <></>;
}

export default Login;
