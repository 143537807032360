import { MouseEvent, MouseEventHandler, useContext, useEffect, useRef, useState } from 'react';
import validators from "modules/validators";
import { SonareApi, SoContext, FileInfoDto, FileAccessDto, RegistrationFileInfoDto, SoContextType, RegistrationFileAccessDto } from 'react-sonare';
import { ButtonGroup, Col, Form, InputGroup, Row, ToggleButton } from 'react-bootstrap';
import { ToastContext, ToastContextType } from './toast';

export type CodeType = {
    code: string | number | null;
    name: string;
};

export class RegistrationFile {
    get passCode(): string | null {
        return null;
    }
    get expired(): boolean {
        return true;
    }
    get enabled(): boolean {
        return false;
    }
    get fileInfo(): RegistrationFileInfoDto | null {
        return null;
    }
    async onFiles(files?: FileList): Promise<RegistrationFileInfoDto | null> {
        return new Promise<RegistrationFileInfoDto | null>((resolve, reject) => {
            reject();
        });
    }
    async persistence(relation: number | null, publishDate: string): Promise<FileInfoDto> {
        return new Promise<FileInfoDto>((resolve, reject) => {
            reject();
        });
    }
    toString(): string {
        return "null";
    }
}

export class RegistrationFileImpl extends RegistrationFile {
    _fileAccess: RegistrationFileAccessDto | null;
    _fileInfo: RegistrationFileInfoDto | null;
    _isEnabled: () => boolean;
    _onFiles: (files: FileList) => Promise<RegistrationFileInfoDto | null>;
    constructor(enabled: () => boolean, onFiles: (files: FileList) => Promise<RegistrationFileInfoDto | null>) {
        super();
        this._fileAccess = null;
        this._fileInfo = null;
        this._isEnabled = enabled;
        this._onFiles = onFiles;
    }
    public get fileAccess(): RegistrationFileInfoDto | null {
        return this._fileInfo;
    }
    public set fileAccess(fileAccess: RegistrationFileAccessDto | null) {
        this._fileAccess = fileAccess;
    }
    public get fileInfo(): RegistrationFileInfoDto | null {
        return this._fileInfo;
    }
    public set fileInfo(value: RegistrationFileInfoDto | null) {
        this._fileInfo = value;
    }
    get passCode() {
        return this._fileAccess === null ? null : this._fileAccess.passCode;
    }
    get expired() {
        return this._fileAccess === null ? true : this._fileAccess.expire.getTime() <= new Date().getTime();
    }
    get enabled() {
        return this._fileAccess !== null;
    }
    async onFiles(files: FileList): Promise<RegistrationFileInfoDto | null> {
        if(this._fileAccess) {
            return this._onFiles(files);
        } else {
            return super.onFiles(files);
        }
    }
    toString() {
        return this._fileAccess === null ? "null" : this._fileAccess.passCode;
    }
};

export type ValidatorType = (value: any) => string | null;
export type FormValueType = null | boolean | string | number | Date | RegistrationFile | Array<string> | Array<number>;
export type FormValuesType = {[key: string]: FormValueType};
export type FormValidatorType = ValidatorType[];
export type FormValidatorsType = {[key: string]: FormValidatorType};
export type FormErrorsType = {[key: string]: string};

export type FormInputType =
    "text" |
    "textArea" |
    "password" |
    "email" |
    "select" |
    "radio" |
    "checkbox" |
    "date" |
    "dateTime" |
    "minute" |
    "file" |
    "switch" |
    "lever" |
    null;

export type FormItemTypeProps<T extends FormValueType> = {
    name: string | null,
    label: string,
    placeholder?: string;
    type: FormInputType,
    required?: boolean | null,
    validators?: Array<ValidatorType>,
    initialValue?: FormValueType,
    readOnly?: boolean,
    listItem?: Array<CodeType>,
    onClick?: (value: T) => void,
    onChange?: (value: T) => void,
}

export class FormItemType {
    name: string;
    label: string;
    placeholder?: string;
    type: FormInputType;
    required?: boolean | null;
    validators?: Array<ValidatorType>;
    initialValue: FormValueType;
    readOnly?: boolean;
    listItem?: Array<CodeType>
    onClick?: (value: FormValueType) => void;
    onChange?: (value: FormValueType) => void;
    constructor(props: FormItemTypeProps<FormValueType>) {
        this.name = props.name;
        this.label = props.label;
        this.placeholder = props.placeholder;
        this.type = props.type;
        this.required = props.required;
        this.validators = props.validators;
        this.initialValue = props.initialValue;
        this.readOnly = props.readOnly;
        this.listItem = props.listItem;
        this.onClick = props.onClick;
        this.onChange = props.onChange;
    }
}

export class FormItemGroupType {
    name: string;
    label?: string;
    items: Array<FormItemType>;
    constructor(items: Array<FormItemType>, name: string, label: string = undefined) {
        this.items = items;
        this.name = name;
        this.label = label;
    }
}

export class FileValueType {
    passCode: string | null;
    expire: Date | null;
}

export type ChangeEventType = {
    target: {
        name: string;
        value: null | boolean | string | number | Date | RegistrationFile | Array<string> | Array<number>;
    }
}

function toMap<T>(items: Array<FormItemType | FormItemGroupType>) {
    const map: {[key: string]: FormItemType} = {};
    for (const item of items) {
        if(item instanceof FormItemGroupType) {
            for (const groupItem of item.items) {
                map[groupItem.name] = groupItem;
            }
        } else {
            map[item.name] = item;
        }
    }
    return map;
}

function toInitialValues(items: {[key: string]: FormItemType}) {
    const initialValues: FormValuesType = {};
    for (const name in items) {
        initialValues[name] = items[name].initialValue;
    }
    return initialValues;
}

const SELECT_INPUT_TYPES = [
    "select",
    "radio",
    "checkbox",
    "file",
];

function toRequiredMessages(items: {[key: string]: FormItemType}) {
    const requiredMessages: {[key: string]: string} = {};
    for (const name in items) {
        const item: FormItemType = items[name];
        if(item.required) {
            if(SELECT_INPUT_TYPES.includes(item.type)) {
                requiredMessages[name] = "選択されていません。"
            } else {
                requiredMessages[name] = "入力されていません。"
            }
        } else {
            requiredMessages[name] = null;
        }
    }
    return requiredMessages;
}

function isEmpty(value: FormValueType) {
    let isEmpty = value === null;
    if(!isEmpty) {
        if(value instanceof Array) {
            isEmpty = value.length === 0;
        } else if(value instanceof RegistrationFile) {
            isEmpty = value.fileInfo === null;
        } else {
            isEmpty = value === "";
        }
    }
    return isEmpty;
}

export type FormType = {
    disabled: boolean;
    items: Array<FormItemType | FormItemGroupType>,
    isValid: (name: string) => boolean;
    isInvalid: (name: string) => boolean;
    values: FormValuesType;
    errors: FormErrorsType;
    patch: (patch: any) => void;
    onChange: (event: ChangeEventType) => void;
    onSubmit: () => void;
    reset: () => void;
};

function useForm(itemList: Array<FormItemType | FormItemGroupType>, onSubmit: (values: FormValuesType) => void) {
    const items: {[key: string]: FormItemType} = toMap(itemList);
    const initialValues: {[key: string]: FormValueType} = toInitialValues(items);
    const requiredMessages: {[key: string]: string} = toRequiredMessages(items);

    const [formValues, setFormValues] = useState({ ...initialValues });
    const [formErrors, setFormErrors] = useState({} as FormErrorsType);
    const [withValidation, setWithValidation] = useState(false);

    const validate = (values: FormValuesType) => {
        const errors: FormErrorsType = {};

        for (let name in values) {
            const item = items[name];
            const value = values[name];
            const requiredMessage = requiredMessages[name];
            const empty = isEmpty(values[name]);
            let message: string | null = null;
            if(empty) {
                message = requiredMessages[name];
            }
            if(!empty && item.validators) {
                item.validators.forEach(validator => {
                    if(message == null) {
                        message = validator(values[name]);
                    }
                })
            }
            if(message) {
                errors[name] = message;
            }
        }

        return errors;
    }

    return {
        disabled: 0 < Object.keys(formErrors).length,
        items: itemList,
        values: formValues,
        errors: formErrors,
        isValid: (name: string) => {
            return withValidation && (formErrors[name] === null || formErrors[name] === undefined);
        },
        isInvalid: (name: string) => {
            return withValidation && !(formErrors[name] === null || formErrors[name] === undefined);
        },
        patch: (patch: FormValuesType) => {
            const newValues = { ...formValues }
            for (let name in patch) {
                newValues[name] = patch[name];
            }
            if(withValidation) {
                setFormErrors(validate(newValues));
            }
            setFormValues(newValues);
        },
        onChange: (event: ChangeEventType) => {
            const {target: {name, value}} = event;
            const newValues: FormValuesType = {
                ...formValues,
                [name]: value,
            };
            if(withValidation) {
                setFormErrors(validate(newValues));
            }
            setFormValues(newValues);
        },
        onSubmit: () => {
            let errors = null;
            if(withValidation) {
                errors = formErrors;
            } else {
                errors = validate(formValues);
            }
            if(Object.keys(errors).length === 0) {
                onSubmit(formValues);
            } else if(!withValidation) {
                setFormErrors(errors);
                setWithValidation(true);
            }
        },
        reset: () => {
            const newValues: FormValuesType = {
                ...initialValues
            };
            setWithValidation(false);
            setFormErrors({});
            setFormValues(newValues);
        },
    };
}

export default useForm;

export const useRegistrationFile = (fileId: number | null): RegistrationFile => {
    const [fileAccess, setFileAccess] = useState<RegistrationFileAccessDto | null>(null);
    const [fileInfo, setFileInfo] = useState<RegistrationFileInfoDto | null>(null);

    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);
    
    const onFiles = (files?: FileList) => {
        return new Promise<RegistrationFileInfoDto | null>((resolve, reject) => {
            if(fileAccess === null) {
                reject();
            } else {
                if (files && files[0]) {
                    const api: (passCode: string, file: File) => Promise<RegistrationFileInfoDto> = fileInfo === null ?
                        context.api.registerRegistrationFile:
                        context.api.updateRegistrationFile;
                    api(fileAccess.passCode, files[0])
                    .then(registrationFileInfoDto => {
                        setFileInfo(registrationFileInfoDto);
                        resolve(registrationFileInfoDto);
                    })
                    .catch(error => {
                        reject(error);
                    });
                } else if(fileInfo === null) {
                    resolve(null);
                } else {
                    context.api.deleteRegistrationFile(fileAccess.passCode)
                    .then(registrationFileInfoDto => {
                        setFileInfo(null);
                        resolve(null);
                    })
                    .catch(error => {
                        reject(error);
                    });
                }
            }
        });
    }

    const persistence = async (relation: number | null, publishDate: string): Promise<FileInfoDto> => {
        console.log(`${relation}, ${publishDate}`);
        return new Promise<FileInfoDto>((resolve, reject) => {
            context.api.persistenceRegistrationFile(fileAccess.passCode, relation, publishDate)
            .then(fileInfoDto => {
                setFileInfo(null);
                setFileAccess(null);
                resolve(fileInfoDto);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    useEffect(() => {
        context.api.getRegistrationFileAccess(fileId, 1800)
        .then(registrationFileAccessDto => {
            setFileAccess(registrationFileAccessDto);
            if(fileId !== null) {
                setFileInfo(registrationFileAccessDto);
            }
        })
        .catch(error => {
            toast.error("登録用ファイルアクセスの取得に失敗しました。");
        });
    }, []);

    useEffect(() => {
        if(fileAccess !== null) {
            if(fileId !== null) {
                setFileInfo(fileAccess);
            }
        }
    }, [fileAccess]);

    return {
        passCode: fileAccess === null ? null : fileAccess.passCode,
        expired: fileAccess === null ? true : new Date(fileAccess.expire).getTime() <= new Date().getTime(),
        enabled: fileAccess !== null,
        fileInfo: fileInfo,
        onFiles: onFiles,
        persistence: persistence,
        toString: () => fileAccess === null ? "null" : fileAccess.passCode
    };
};

export const TextItem = (props: FormItemProps) => {
    const {form, item} = props;
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.onChange({
            target: {
                name: item.name,
                value: e.target.value,
            }
        });
        if(item.onChange) {
            item.onChange(e.target.value);
        }
    }
    return <>
        <Form.Label>{item.label}</Form.Label>
        <Form.Control id={item.name} type={item.type} placeholder={item.placeholder} value={form.values[item.name] as string} onChange={onChange} disabled={item.readOnly} />
        {form.errors[item.name] && (
            <div className="form-text text-danger">{form.errors[item.name]}</div>
        )}
    </>;
}

export const TextAreaItem = (props: FormItemProps) => {
    const {form, item} = props;
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.onChange({
            target: {
                name: item.name,
                value: e.target.value,
            }
        });
        if(item.onChange) {
            item.onChange(e.target.value);
        }
    }
    return <>
        <Form.Label>{item.label}</Form.Label>
        <Form.Control as="textarea" rows={5} placeholder={item.placeholder} value={form.values[item.name] as string} onChange={onChange} />
        {form.errors[item.name] && (
            <div className="form-text text-danger">{form.errors[item.name]}</div>
        )}
    </>;
}

export const SelectItem = (props: FormItemProps) => {
    const {form, item} = props;
    const selectRef = useRef(null);
    const onChange = () => {
        const index = selectRef.current.selectedIndex;
        const value = item.listItem[index].code;
        console.log(item.listItem[index]);
        form.onChange({
            target: {
                name: item.name,
                value: value,
            }
        });
        if(item.onChange) {
            item.onChange(value);
        }
    }
    return <>
        <label htmlFor={item.name} className="form-label">{item.label}</label>
        <Form.Select id={item.name} name={item.name} ref={selectRef} onChange={onChange} disabled={item.readOnly}>
            {item.listItem.map((code, index) => 
                <option key={`${item.name}-${index}`} value={index} selected={code.code === form.values[item.name]}>{code.name}</option>
            )}
        </Form.Select>
        {form.errors[item.name] && (
            <div className="form-text text-danger">{form.errors[item.name]}</div>
        )}
    </>;
}

export const SwitchItem = (props: FormItemProps) => {
    const {form, item} = props;
    const onChange = () => {
        const value = !form.values[item.name];
        form.onChange({
            target: {
                name: item.name,
                value: value,
            }
        });
        if(item.onChange) {
            item.onChange(value);
        }
    }
    return <>
        <label htmlFor={item.name} className="form-label">{item.label}</label>
        <div className="form-check form-switch">
            <input name={item.name} type="checkbox" role="switch" className="form-check-input mx-2" onClick={onChange} checked={form.values[item.name] as boolean} />
        </div>
        {form.errors[item.name] && (
            <div className="form-text text-danger">{form.errors[item.name]}</div>
        )}
    </>;
}

export const DateItem = (props: FormItemProps) => {
    const {form, item} = props;
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        console.log(new Date().toISOString())
        form.onChange({
            target: {
                name: item.name,
                value: value,
            }
        });
        if(item.onChange) {
            item.onChange(value);
        }
    }
    return <>
        <Form.Label>{item.label}</Form.Label>
        <Form.Control name={item.name} type="date" onChange={onChange} />
        {form.errors[item.name] && (
            <div className="form-text text-danger">{form.errors[item.name]}</div>
        )}
    </>;
}

export const DateTimeItem = (props: FormItemProps) => {
    const {form, item} = props;

    const dateRef = useRef(null);
    const hourRef = useRef(null);
    const minuteRef = useRef(null);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value: string = e.target.value;
        if(value && 0 < value.length) {
            const hour = hourRef.current.selectedIndex.toString().padStart(2, '0');
            const minute = minuteRef.current.selectedIndex.toString().padStart(2, '0');
            value = `${value}T${hour}:${minute}:00.000`;
        }
        form.onChange({
            target: {
                name: item.name,
                value: value,
            }
        });
        if(item.onChange) {
            item.onChange(value);
        }
    }

    const onTime =(e: React.ChangeEvent<HTMLSelectElement>) => {
        let value: string = dateRef.current.value;
        if(value && 0 < value.length) {
            const hour = hourRef.current.selectedIndex.toString().padStart(2, '0');
            const minute = minuteRef.current.selectedIndex.toString().padStart(2, '0');
            value = `${value}T${hour}:${minute}:00.000`;
            form.onChange({
                target: {
                    name: item.name,
                    value: value,
                }
            });
            if(item.onChange) {
                item.onChange(value);
            }
        }
    };

    return <>
        <label htmlFor={item.name} className="form-label">{item.label}</label>
        <Row>
            <Col xs={6}>
                <Form.Control name={item.name} type="date" onChange={onChange} ref={dateRef} />
            </Col>
            <Col xs={6}>
                <InputGroup>
                    <Form.Select id={item.name} name={`__${item.name}-hour`} ref={hourRef}  onChange={onTime}>
                        {[...Array(24)].map((_, index) =>
                            <option key={`${item.name}-hour-${index}__`} value={index}>{index.toString().padStart(2, '0')}</option>
                        )}
                    </Form.Select>
                    <InputGroup.Text>:</InputGroup.Text>
                    <Form.Select id={item.name} name={`__${item.name}-minute`} ref={minuteRef} onChange={onTime} >
                        {[...Array(60)].map((_, index) =>
                            <option key={`${item.name}-minute-${index}__`} value={index}>{index.toString().padStart(2, '0')}</option>
                        )}
                    </Form.Select>
                </InputGroup>
            </Col>
        </Row>
        {form.errors[item.name] && (
            <div className="form-text text-danger">{form.errors[item.name]}</div>
        )}
    </>;
}

export const LeverItem = (props: FormItemProps) => {
    const {form, item} = props;
    const [leverValue, setLeverValue] = useState<boolean | null>(item.initialValue as boolean | null);
    const onChange = (value: boolean) => {
        setLeverValue(value);
        form.onChange({
            target: {
                name: item.name,
                value: value,
            }
        });
        if(item.onChange) {
            item.onChange(value);
        }
    }
    return <>
        <label className="form-label">{item.label}</label><br />
        <ButtonGroup>
            <ToggleButton id={`${item.name}-left`} name={item.name} type="radio" variant="outline-secondary" checked={leverValue === false} value={"left"} onClick={() => onChange(false)}><i className="bi bi-x"></i></ToggleButton>
            <ToggleButton id={`${item.name}-center`} name={item.name} type="radio" variant="outline-secondary" checked={leverValue === null} value={"center"} onClick={() => onChange(null)}><i className="bi bi-dash"></i></ToggleButton>
            <ToggleButton id={`${item.name}-right`} name={item.name} type="radio" variant="outline-secondary" checked={leverValue === true} value={"right"} onClick={() => onChange(true)}><i className="bi bi-circle"></i></ToggleButton>
            {form.errors[item.name] && (
                <div className="form-text text-danger">{form.errors[item.name]}</div>
            )}
        </ButtonGroup>
    </>
}

export const FileItem = (props: FormItemProps) => {
    const {form, item} = props;
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);
    const registrationFile = useRegistrationFile(item.initialValue as number | null);

    const fileRef = useRef(null);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        registrationFile.onFiles(e.target.files)
        .then(file => {
            form.onChange({ target: {
                name: item.name,
                value: registrationFile,
            }});
            if(item.onChange) {
                item.onChange(registrationFile);
            }
        });
    };

    const onUpload = () => {
        fileRef.current.click();
    };

    const onTrash = () => {
        registrationFile.onFiles();
    };

    useEffect(() => {
        if(registrationFile.enabled) {
            console.log(registrationFile);
            form.onChange({ target: {
                name: item.name,
                value: registrationFile,
            }});
        }
    }, [registrationFile.enabled]);

    return <>
        {registrationFile.enabled ? <>
            <label htmlFor={item.name} className="form-label">{item.label}</label>
            <input type="file" ref={fileRef} onChange={onChange} hidden={true}/>
            <div className="input-group">
                <input className="form-control" type="text" name={`${item.name}-text`} value={registrationFile.fileInfo === null ? "" : registrationFile.fileInfo.fileName} readOnly={true} />
                {registrationFile.fileInfo !== null &&
                    <button className="btn btn-outline-secondary" onClick={onTrash}>
                        <i className="bi bi-trash"></i>
                    </button>
                }
                <button className="btn btn-outline-secondary" onClick={onUpload}>
                    <i className="bi bi-upload"></i>
                </button>
            </div>
            {form.errors[item.name] && (
                <div className="form-text text-danger">{form.errors[item.name]}</div>
            )}
        </> : <>
            <label className="form-label">{item.label}</label>
            <span>処理中...</span>
        </>}
    </>
}

export type FormItemProps = {
    form: FormType,
    item: FormItemType,
};

export const FormItem = (props: FormItemProps) => {
    return <>
        {props.item.type === "text" && <TextItem { ... props } />}
        {props.item.type === "textArea" && <TextAreaItem { ... props } />}
        {props.item.type === "select" && <SelectItem { ... props } />}
        {props.item.type === "file" && <FileItem { ... props } />}
        {props.item.type === "switch" && <SwitchItem { ... props } />}
        {props.item.type === "date" && <DateItem { ... props } />}
        {props.item.type === "dateTime" && <DateTimeItem { ... props } />}
        {props.item.type === "lever" && <LeverItem { ... props } />}
    </>;
}

export type FormItemGroupProps = {
    form: FormType,
    group: FormItemGroupType,
};

export const FormItemGroup = (props: FormItemGroupProps) => {
    return <Row className="mb-3">
        {props.group.items.map((item) => (
            <Col>
                <FormItem form={props.form} item={item} />
            </Col>
        ))}
    </Row>;
}

export const FormItems = (form: FormType) => {
    return <>
        {form.items.map((item, index) => (
            item instanceof FormItemGroupType ? (
                <FormItemGroup key={`${item.name}-${index}`} form={form} group={item} /> ) :
            item instanceof FormItemType ?
                <Row className="mb-3">
                    <Col>
                        <FormItem key={`${item.name}-${index}`} form={form} item={item} />
                    </Col>
                </Row> : <></>
        ))}
    </>
}
