import React, { useContext, useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap'
import { SoContext, SoContextType } from 'react-sonare';
import LOGO from 'images/sonare-mix-w.svg';
import { ToastContext, ToastContextType } from 'modules/toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const headerStyle = {
    position: "fixed",
    display: "flex",
    alignItems: "flex-end",
    top: 0,
    left: 0,
    width: "100%",
    height: "5em",
};

const Header: React.FC = () => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);
    const navigate = useNavigate();

    const onLogin = () => {
        context.login(`${process.env.REACT_APP_ORIGIN}/contract/login`);
    }

    const onLogout = () => {
        console.log("logout");
        context.logout()
        .then(() => {
            toast.info("ログアウトしました。");
        })
        .catch(error => {
            toast.error("ログアウトに失敗しました。");
        })
    }

    return (<>
        <div className="">
            <Navbar collapseOnSelect className="bg-body-tertiary justify-content-between" expand="lg">
                <Navbar.Brand>
                    <img src={LOGO} height="48" />
                </Navbar.Brand>
                <Navbar.Text className="pt-4 fs-4">Applications.</Navbar.Text>
                <Navbar.Toggle className="mr-4c" aria-controls="responsive-navbar-nav"></Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    {context.user ? 
                    <Nav className="ms-auto">
                        <Nav.Link>
                            <i className="bi bi-person" style={{fontSize: "2rem"}}></i>
                        </Nav.Link>
                        <Nav.Link onClick={onLogout}>
                            <i className="bi bi-box-arrow-right"  style={{fontSize: "2rem"}}></i>
                        </Nav.Link>
                    </Nav> :
                    <Nav className="ms-auto">
                        <Nav.Link onClick={onLogin}>
                            <i className="bi bi-box-arrow-in-right"  style={{fontSize: "2rem"}}></i>
                        </Nav.Link>
                    </Nav>}
                </Navbar.Collapse>
            </Navbar>
            <hr />
        </div>
    </>);
}

export default Header;
