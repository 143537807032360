import React, { useContext } from 'react'
import { RelationDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import 'scss/application.scss';
import { Button, Modal } from 'react-bootstrap';

type RelationDeleteProps = {
    relation: RelationDto,
    onClose: (done: boolean) => void,
};

export const RelationDelete = (props: RelationDeleteProps) => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);

    const onDelete = () => {
        context.api.deleteRelation(props.relation.relation)
        .then(() => {
            toast.info("関係を削除しました。");
            props.onClose(true);
        })
        .catch(error => {
            toast.error("関係の削除に失敗しました。");
            props.onClose(false);
        });
    };

    return (
        <Modal show={true} onHide={() => props.onClose(false)} scrollable={false}>
            <Modal.Header closeButton>
            関係を削除しますがよろしいですか？
            </Modal.Header>
            <Modal.Body className="m-4">
                <p>{props.relation.relation}</p>
                <p>{props.relation.relationName}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.onClose(false)}>いいえ</Button>
                <Button variant="primary" onClick={onDelete}>はい</Button>
            </Modal.Footer>
        </Modal>
    );
}
