import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SoContext } from 'react-sonare';
import { ToastContext } from 'modules/toast';
import 'scss/application.scss';
import { ClientDto, ClientRegisterDto, useManagementApi } from 'modules/managementApi';
import useForm, { FormItemType, FormItems, FormValuesType, RegistrationFile, useRegistrationFile } from 'modules/form';
import validators from 'modules/validators';
import { toDateString } from 'modules/utils';

export const ClientRegister = () => {
    const context = useContext(SoContext);
    const toast = useContext(ToastContext);
    const params = useParams();

    const [client, setClient] = useState<ClientDto | null | undefined>(undefined);

    const navigate = useNavigate();
    const managementApi = useManagementApi(context);

    const actionName = params.clientId ? "編集" : "登録"

    const exists: boolean = client !== undefined && client !== null;

    const form = useForm([
        new FormItemType({
            name: "clientId",
            label: "クライアントID",
            type: "text",
            required: true,
            validators: validators.clientId,
            initialValue: exists ? client.clientId : "",
        }),
        new FormItemType({
            name: "clientName",
            label: "クライアント名",
            type: "text",
            required: true,
            validators: validators.relationName,
            initialValue: exists ? client.clientName : "",
        }),
        new FormItemType({
            name: "clientSecret",
            label: "シークレット",
            type: "text",
            required: true,
            validators: validators.relationName,
            initialValue: exists ? client.clientSecret : "",
        }),
        new FormItemType({
            name: "accessSeconds",
            label: "アクセストークン有効時間（秒）",
            type: "text",
            required: true,
            validators: validators.validSeconds,
            initialValue: exists ? `${client.accessSeconds}` : "",
        }),
        new FormItemType({
            name: "refreshSeconds",
            label: "リフレッシュトークン有効時間（秒）",
            type: "text",
            required: true,
            validators: validators.validSeconds,
            initialValue: exists ? `${client.refreshSeconds}` : "",
        }),
        new FormItemType({
            name: "management",
            label: "管理権限",
            type: "switch",
            required: true,
            initialValue: exists ? client.management : false,
        }),
        new FormItemType({
            name: "passCode",
            label: "画像ファイル",
            type: "file",
            required: false,
            initialValue: exists ? client.fileId : null,
        }),
        new FormItemType({
            name: "color",
            label: "色",
            type: "text",
            required: false,
            initialValue: exists ? `${client.color}` : "",
        }),
        new FormItemType({
            name: "origin",
            label: "オリジン",
            type: "text",
            required: false,
            initialValue: exists ? client.origin : "",
        }),
        new FormItemType({
            name: "callbackUrl",
            label: "コールバックURL",
            type: "text",
            required: false,
            initialValue: exists ? client.callbackUrl : "",
        }),
    ], (values: FormValuesType) => {
        const clientId = values["clientId"] as string;

        (values["passCode"] as RegistrationFile).persistence(null, toDateString(new Date()))
        .then(fileInfo => {
            const clientRegisterDto: ClientRegisterDto = {
                clientName: values["clientName"] as string,
                clientSecret: values["clientSecret"] as string,
                accessSeconds: parseInt(values["accessSeconds"] as string),
                refreshSeconds: parseInt(values["refreshSeconds"] as string),
                management: values["management"] as boolean,
                fileId: fileInfo === null ? null : fileInfo.fileId,
                color: null,
                origin: values["origin"] as string,
                loginUrl: values["loginUrl"] as string,
                callbackUrl: values["callbackUrl"] as string,
            };
            console.log(clientRegisterDto);
            const api: (clientId: string, clientRegisterDto: ClientRegisterDto) => Promise<void> = client === null ? managementApi.registerClient : managementApi.updateClient;
            api(clientId, clientRegisterDto)
            .then(() => {
                navigate("/client");
            })
            .catch(error => {
                toast.error(`クライアントの登録に失敗しました。`);
            });
        })
        .catch(error => {
            toast.error(`ファイルの永続化に失敗しました。`);
        });
    });

    useEffect(() => {
        if(params.clientId) {
            managementApi.getClient(params.clientId)
            .then(clientDto => {
                setClient(clientDto);
            })
            .catch(error => {
                toast.error("クライアントリストの取得に失敗しました。");
            });
        } else {
            setClient(null);
        }
    }, []);

    return (
        <div>
            <h3 className="py-4">{context.user.relation_name} クライアント{actionName}</h3>
            {client !== undefined && <FormItems { ...form } />}
            <div className="d-flex justify-content-center py-2">
                <button className="btn btn-primary" onClick={form.onSubmit}>{actionName}</button>
            </div>
            <div className="d-flex justify-content-center py-2">
                <Link to={"/client"}>
                    <span>戻る</span>
                </Link>
            </div>
        </div>
    );
}
