import React, { useContext, useEffect, useState } from 'react'
import { RelationDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import 'scss/application.scss';
import { Button, Modal } from 'react-bootstrap';
import useForm, { CodeType, FormItemGroupType, FormItemType, FormItems, FormValuesType, RegistrationFile } from 'modules/form';
import validators from 'modules/validators';
import { toCodeArray } from 'modules/utils';
import { useRelations } from 'modules/relations';

type FileRegisterProps = {
    onClose: (done: boolean) => void,
};

const noRelation: CodeType = {code: null, name: "（指定なし）"};

export const FileRegister = (props: FileRegisterProps) => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);

    const relations = useRelations();

    const form = useForm([
        new FormItemType({
            name: "fileId",
            label: "ファイル",
            type: "file",
            required: true,
            initialValue: null,
        }),
        new FormItemType({
            name: "relation",
            label: "関係",
            type: "select",
            required: false,
            initialValue: null,
            listItem: relations,
        }),
        new FormItemType({
            name: "publishTime",
            label: "公開日時",
            type: "dateTime",
            required: false,
            initialValue: null,
        }),
    ], (values: FormValuesType) => {
        console.log(values);
        (values["fileId"] as RegistrationFile).persistence(values["relation"] as number | null, values["publishTime"] as string)
        .then(fileInfo => {
            toast.info("ファイルを登録しました。");
            props.onClose(true);
        }).catch(error => {
            toast.error("ファイルの登録に失敗しました。");
            props.onClose(false);
        });
    });

    return (
        <div className={"modal show"} style={{ display: 'block', position: 'initial' }}>
            <Modal show={true} size="lg" onHide={() => props.onClose(false)} scrollable={false}>
                <Modal.Header closeButton>
                    ファイル登録
                </Modal.Header>
                <Modal.Body className="m-4">
                    <FormItems { ...form } />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.onClose(false)}>キャンセル</Button>
                    <Button variant="primary" onClick={form.onSubmit}>登録</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
