import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthorizationDto, PersonDto, RelationDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import { ClientDto, useManagementApi } from 'modules/managementApi';
import 'scss/application.scss';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { AuthorizationRegister } from './AuthorizationRegister';
import { AuthorizationDelete } from './AuthorizationDelete';

type AuthorizationCandidateType = {
    person: PersonDto,
    relations: Array<RelationDto>,
};

export const AuthorizationList = () => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);
    const params = useParams();
    const navigate = useNavigate();
    const managementApi = useManagementApi(context);

    const [client, setClient] = useState<ClientDto | undefined>(undefined);
    const [authorizations, setAuthorizations] = useState<Array<AuthorizationDto> | undefined>(undefined);
    const [authorization, setAuthorization] = useState<AuthorizationDto | undefined>(undefined);
    const [register, setRegister] = useState<boolean>(false);
    const [persons, setPersons] = useState<Array<PersonDto> | undefined>(undefined);
    const [authorizationCandidate, setAuthorizationCandidate] = useState<AuthorizationCandidateType | undefined>(undefined);

    useEffect(() => {
        if(params.clientId) {
            managementApi.getClient(params.clientId)
            .then(clientDto => {
                setClient(clientDto);
            })
            .catch(error => {
                toast.error("クライアントの取得に失敗しました。");
            });
        } else {
            setClient(null);
        }
    }, []);

    useEffect(() => {
        if(client !== null) {
            load();
        }
    }, [client]);

    const load = () => {
        context.api.listAuthorization(params.clientId, null)
        .then(authorizationListDto => {
            setAuthorizations(authorizationListDto.authorizations);
        })
        .catch(error => {
            toast.error("認可リストの取得に失敗しました。");
        })
    };

    const onClose = (done: boolean) => {
        setAuthorization(undefined);
        setRegister(false);
        setRegister(undefined);
        if(done) {
            load();
        }
    };
    
    return (
        <div>
            <h3 className="py-4">{`${context.user.relation_name} ${client === undefined ? " " : client.clientName} 認可リスト`}</h3>
            <div className="d-flex flex-row-reverse">
                <Button variant="outline-secondary" onClick={() => setRegister(true)}>
                    <i className="bi bi-plus"></i>
                </Button>
            </div>
            {(authorizations !== undefined && authorizations.length === 0) && (
                <div className="col col-12 d-flex justify-content-center py-5">
                    <span>登録されていません。</span>
                </div>
            )}
            {(authorizations !== undefined && 0 < authorizations.length) &&
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Relation</th>
                            <th scope="col">LastLogin</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {authorizations.map((authorization, index) =>
                            <tr key={authorization.personId}>
                                <td scope="col" className="text-truncate">{authorization.name}</td>
                                <td scope="col" className="text-truncate">{authorization.email}</td>
                                <td scope="col" className="text-truncate">{authorization.personId === authorization.relation ? "（指定なし）" : authorization.relationName}</td>
                                <td scope="col" className="text-truncate">{authorization.lastLogin === null ? "" : authorization.lastLogin.toISOString()}</td>
                                <td scope="col" className="text-truncate">
                                    <Button variant="outline-secondary" className="mx-2" onClick={() => setAuthorization(authorization)}>
                                        <i className="bi bi-trash"></i>
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
            <div className="d-flex justify-content-center py-2">
                <Link to={`/client`}>
                    <span>戻る</span>
                </Link>
            </div>
            <div className={"modal show"} style={{ display: 'block', position: 'initial' }}>
                {register && <AuthorizationRegister client={client} onClose={onClose} />}
                {authorization && <AuthorizationDelete client={client} authorization={authorization} onClose={onClose} />}
            </div>
        </div>
    );
}
