import React from 'react';

const Footer: React.FC = () => {
    const style = {
        
    } as React.CSSProperties;

    return (
        <div className="mx-4">
            <hr />
            <p className="text-center">Copyright 2024 Roce,Inc.</p>
        </div>
    );
}

export default Footer;
