import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { SoContext, SoContextType } from 'react-sonare';

const NotFound = () => {
    const context: SoContextType = useContext(SoContext);

    return (
        <div>
            <h3>NotFound.</h3>
            <div>指定のページは見つかりませんでした。</div>
            <ul>
                <li><Link to="/">トップページへ</Link></li>
            </ul>
        </div>
    );
}

export default NotFound;
