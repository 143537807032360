import { LoadingContext, LoadingContextType } from 'modules/loading';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PersonDto, PersonRegisterDto, RelationDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import { ClientDto, useManagementApi } from 'modules/managementApi';
import 'scss/application.scss';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { FormValuesType } from 'modules/form';
import { RelationDelete } from './RelationDelete';
import { RelationRegister } from './RelationRegister';

type RelationForRegister = {
    relation?: number,
    relationName: string,
};

export const RelationList = () => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);
    const navigate = useNavigate();

    const [relations, setRelations] = useState<Array<RelationDto> | undefined>(undefined);
    const [forRegister, setForRegister] = useState<RelationForRegister>(undefined);
    const [forDelete, setForDelete] = useState<RelationDto | undefined>(undefined);

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
    }, [forRegister]);

    const load = () => {
        context.api.listRelation(true)
        .then(relationListDto => {
            setRelations(relationListDto.relations);
        })
        .catch(error => {
            toast.error("関係リストの取得に失敗しました。");
        })
    };

    const onClose = (done: boolean) => {
        if(forRegister) {
            setForRegister(undefined);
        }
        if(forDelete) {
            setForDelete(undefined);
        }
        if(done) {
            load();
        }
    };

    return (
        <div>
            <h3 className="py-4">{`${context.user.relation_name} 関係リスト`}</h3>
            <div className="d-flex flex-row-reverse">
                <Button variant="outline-secondary" onClick={() => setForRegister({relationName: ""})}>
                    <i className="bi bi-plus"></i>
                </Button>
            </div>
            {(relations !== undefined && relations.length === 0) && (
                <div className="col col-12 d-flex justify-content-center py-5">
                    <span>登録されていません。</span>
                </div>
            )}
            {(relations !== undefined && 0 < relations.length) &&
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">名称</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {relations.map((relation, index) =>
                            <tr key={relation.relation}>
                                <td scope="col" className="text-truncate">{relation.relation}</td>
                                <td scope="col" className="text-truncate">{relation.relationName}</td>
                                <td scope="col" className="text-truncate">
                                    <Button variant="outline-secondary" className="mx-2" onClick={() => setForRegister(relation)}>
                                        <i className="bi bi-pencil"></i>
                                    </Button>
                                    <Button variant="outline-secondary" className="mx-2" onClick={() => setForDelete(relation)}>
                                        <i className="bi bi-trash"></i>
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
            <div className="d-flex justify-content-center py-2">
                <Link to={`/`}>
                    <span>戻る</span>
                </Link>
            </div>
            <div className={"modal show"} style={{ display: 'block', position: 'initial' }}>
                {forRegister && <RelationRegister { ...forRegister } onClose={onClose} />}
                {forDelete && <RelationDelete relation={forDelete} onClose={onClose} />}
            </div>
        </div>
    );
}
