import React, { useContext } from 'react'
import { AnnouncementDto, SoContext, SoContextType } from 'react-sonare';
import { ToastContext, ToastContextType } from 'modules/toast';
import 'scss/application.scss';
import { Button, Modal } from 'react-bootstrap';
import { toDateTimeString, withBr } from 'modules/utils';

type MailTemplateDeleteProps = {
    category: string,
    announcement: AnnouncementDto,
    onClose: (done: boolean) => void,
};

export const AnnouncementDelete = (props: MailTemplateDeleteProps) => {
    const context: SoContextType = useContext(SoContext);
    const toast: ToastContextType = useContext(ToastContext);

    const onDelete = () => {
        context.api.deleteAnnouncement(props.announcement.announcementId)
        .then(() => {
            toast.info("公告を削除しました。");
            props.onClose(true);
        })
        .catch(error => {
            toast.error("公告の削除に失敗しました。");
            props.onClose(false);
        });
    };

    return (
        <Modal show={true} onHide={() => props.onClose(false)} scrollable={false}>
            <Modal.Header closeButton>
            公告を削除しますがよろしいですか？
            </Modal.Header>
            <Modal.Body className="m-4">
                <p>{props.category}</p>
                <p>{props.announcement.announcementName}</p>
                <p>{toDateTimeString(props.announcement.publishTime)}</p>
                <p>{withBr(props.announcement.content)}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.onClose(false)}>いいえ</Button>
                <Button variant="primary" onClick={onDelete}>はい</Button>
            </Modal.Footer>
        </Modal>
    );
}
